import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import 'react-nestable/dist/styles/index.css';
import LinkButton from '../../components/common/LinkButton';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';

const DepartingCityOrder = () => {
      const [loading, setLoading] = useState(false);
      const [cities, setCities] = useState([]);
      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10000);
      const [orders, setOrders] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/departing_cities?limit=${limit}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setCities(response.data.cities);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit]);

      const handleDragStart = (e, index) => {
            e.dataTransfer.setData('index', index.toString());
      };

      const handleDragOver = async (e, order) => {
            e.preventDefault();
            setOrders(order);
      };

      const handleDrop = async (e, dropIndex) => {
            const dragIndex = parseInt(e.dataTransfer.getData('index'));
            const draggedProduct = cities[dragIndex];

            const updatedProducts = cities.filter(
                  (item, index) => index !== dragIndex
            );

            updatedProducts.splice(dropIndex, 0, draggedProduct);

            setCities(updatedProducts);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/departing_cities/orders/change`,
                        {
                              cities: updatedProducts
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        console.log(response.data);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Destination City Order Manage">
                                          <LinkButton
                                                link="/dashboard/departing_cities"
                                                title="Back"
                                          />
                                    </CardHeader>
                              </Card>
                        </Col>
                  </Row>
                  <div className="mt-4">
                        {cities?.map((item, index) => (
                              <>
                                    <div
                                          className="draggable-row draggable-item"
                                          key={index}
                                          draggable="true"
                                          onDragStart={(e) =>
                                                handleDragStart(e, index)
                                          }
                                          onDragOver={(e) =>
                                                handleDragOver(e, cities)
                                          }
                                          onDrop={(e) => handleDrop(e, index)}
                                    >
                                          <div className="text-start">
                                                <div>{item.title}</div>
                                          </div>
                                    </div>
                              </>
                        ))}
                  </div>
            </>
      );
};

export default DepartingCityOrder;
