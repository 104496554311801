import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ProjectSwiper = styled(Swiper)`
      img {
            height: 320px;
      }
`;

export const ProjectThumbnail = styled(Swiper)`
      border: 1px solid #f2f2f2;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;

      img {
            height: 100px;
            width: 100%;
      }
`;
