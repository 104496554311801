import React, { useState } from 'react';
import ProfileLayout from '../ProfileLayout';
import ProfileTitle from '../../components/profile/ProfileTitle';

import $ from 'jquery';
import { Form } from 'react-bootstrap';
import { FormInput, FormButton } from '../../components/form';
import axios from 'axios';
import toast from 'react-hot-toast';

function ChangePassword() {
      const [loading, setLoading] = useState(false);
      const [oldPassword, setOldPassword] = useState('');
      const [password, setPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');
      const token = JSON.parse(localStorage.getItem('token'));

      const handleFormSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  oldpassword: oldPassword,
                  password: password,
                  password_confirmation: confirmPassword
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/password/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result == 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message);
                              }
                              if (response.data.message.oldpassword) {
                                    toast.error(
                                          response.data.message.oldpassword
                                    );
                              }
                              if (response.data.message.password) {
                                    toast.error(response.data.message.password);
                              }
                              if (response.data.message.password_confirmation) {
                                    toast.error(
                                          response.data.message
                                                .password_confirmation
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <ProfileLayout>
                        <ProfileTitle title="Update Password" />

                        <Form className="row gap-3" onSubmit={handleFormSubmit}>
                              <FormInput
                                    title="Old Password"
                                    type="password"
                                    handleChange={setOldPassword}
                                    required={true}
                              />
                              <FormInput
                                    title="New Password"
                                    type="password"
                                    handleChange={setPassword}
                                    required={true}
                              />
                              <FormInput
                                    title="Confirm Password"
                                    handleChange={setConfirmPassword}
                                    type="password"
                                    required={true}
                              />
                              <div className="col-md-12">
                                    <FormButton
                                          loading={loading}
                                          title="Update"
                                    />
                              </div>
                        </Form>
                  </ProfileLayout>
            </>
      );
}

export default ChangePassword;
