import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import ImageSlider from '../../components/sliders';

const DestinationShow = () => {
      const { destinationId } = useParams();

      const [loading, setLoading] = useState(false);
      const [destination, setDestination] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/destinations/${destinationId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDestination(response.data.destination);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Destination Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/destinations"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            {destination.banner_image && (
                                                                  <div className="w-100">
                                                                        <img
                                                                              src={`${destination.banner_image}`}
                                                                              className="w-100 h-100"
                                                                              style={{
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                  </div>
                                                            )}
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        destination.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        destination.category
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        destination.date
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="User Name"
                                                                  value={
                                                                        destination.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Title"
                                                                  value={
                                                                        destination.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Keyword"
                                                                  value={
                                                                        destination.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Description"
                                                                  value={
                                                                        destination.seo_description
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        destination.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={5}>
                                                      <div>
                                                            <ImageSlider
                                                                  image={
                                                                        destination.image
                                                                  }
                                                                  images={
                                                                        destination.images
                                                                  }
                                                            />
                                                      </div>
                                                      {destination.description && (
                                                            <div className="mt-5 border-top py-2">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: destination.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      )}
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DestinationShow;
