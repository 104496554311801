import React from 'react';
import { Accordion } from 'react-bootstrap';

import ItineraryCreate from './ItineraryCreate';

const ExtraDetail = ({ itinearies, setItinearies, slug, type }) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>Itinerary</Accordion.Header>
                              <Accordion.Body>
                                    <ItineraryCreate
                                          itinearies={itinearies}
                                          setItinearies={setItinearies}
                                          type={type}
                                          slug={slug}
                                    />
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default ExtraDetail;
