import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ImageViewSlider = styled(Swiper)`
      height: 280px;

      img {
            width: 100%;
            height: 100%;
      }
`;

export const ImageThumbnailSlider = styled(Swiper)`
      height: 120px;
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 10px;

      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
      }
`;
