import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CardHeader from '../../components/common/CardHeader';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardBody from '../../components/common/CardBody';

import toast from 'react-hot-toast';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import ServiceListBox from '../../components/backend/ServiceListBox';

const ServiceList = () => {
      const [loading, setLoading] = useState(false);
      const [services, setServices] = useState([]);
      const [totalServices, setTotalServices] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/services?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setServices(response.data.services);
                        setTotalServices(response.data.totalServices);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/services?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setServices([]);
                        setServices(response.data.services);
                        setTotalServices(response.data.totalServices);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Service List">
                                          <div className="d-flex">
                                                <Link
                                                      to="/dashboard/services/create"
                                                      className="btn btn-link me-2"
                                                >
                                                      Add New
                                                </Link>
                                          </div>
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          totalItems={totalServices}
                                          getData={getData}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                    >
                                          <ServiceListBox
                                                loading={loading}
                                                setLoading={setLoading}
                                                services={services}
                                                loadData={loadData}
                                                setServices={setServices}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ServiceList;
