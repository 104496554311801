import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from '../backend/dashboard';

// Category
import {
      CategoryList,
      CategoryCreate,
      CategoryEdit,
      CategoryShow
} from '../backend/category';
import {
      PackageCreate,
      PackageEdit,
      PackageList,
      PackageShow
} from '../backend/packages';
import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow
} from '../backend/banner';
import {
      TestimonialList,
      TestimonialCreate,
      TestimonialShow,
      TestimonialEdit
} from '../backend/testimonials';
import CatalogueList from '../backend/catalogue/CatalogueList';
import CatalogueCreate from '../backend/catalogue/CatalogueCreate';
import CatalogueShow from '../backend/catalogue/CatalogueShow';
import CatalogueEdit from '../backend/catalogue/CatalogueEdit';
import { PageList, PageCreate, PageEdit, PageShow } from '../backend/pages';
import { RoleCreate, RoleEdit, RoleList } from '../backend/roles';
import {
      BlogCategoryList,
      BlogCategoryCreate,
      BlogCategoryEdit,
      BlogCategoryShow
} from '../backend/blogCategory';
import { BlogCreate, BlogEdit, BlogShow, BlogTable } from '../backend/blog';
import {
      FaqTypeCreate,
      FaqTypeEdit,
      FaqTypeList,
      FaqTypeShow
} from '../backend/faqType';
import { FaqCreate, FaqEdit, FaqList, FaqShow } from '../backend/faqs';
import {
      FaqRequestEdit,
      FaqRequestList,
      FaqRequestShow
} from '../backend/faqRequest';
import {
      PluginCreate,
      PluginEdit,
      PluginList,
      PluginShow
} from '../backend/plugins';
import { JobCreate, JobEdit, JobList, JobShow } from '../backend/jobs';
import { CandidateList, CandidateShow } from '../backend/candidates';
import {
      ComplainTypeCreate,
      ComplainTypeEdit,
      ComplainTypeList,
      ComplainTypeShow
} from '../backend/complainTypes';
import { ComplainList, ComplainShow } from '../backend/complains';
import {
      SubscriberCreate,
      SubscriberEdit,
      SubscriberList
} from '../backend/subscribers';
import {
      ContactRequestList,
      ContactRequestShow
} from '../backend/contactRequest';
import { UserCreate, UserEdit, UserList, UserShow } from '../backend/users';
import PageNotFound from '../components/PageNotFound';

import {
      ProjectCreate,
      ProjectEdit,
      ProjectList,
      ProjectShow
} from '../backend/projects';
import {
      ServiceCreate,
      ServiceEdit,
      ServiceList,
      ServiceShow
} from '../backend/services';
import {
      HotelCategoryCreate,
      HotelCategoryEdit,
      HotelCategoryList,
      HotelCategoryShow
} from '../backend/hotel_categories';
import {
      ExperienceCreate,
      ExperienceEdit,
      ExperienceList,
      ExperienceShow
} from '../backend/experiences';
import {
      CountryCreate,
      CountryEdit,
      CountryList,
      CountryShow
} from '../backend/countries';
import {
      VehicleCreate,
      VehicleEdit,
      VehicleList,
      VehicleShow
} from '../backend/vehicles';
import {
      DestinationCategoryCreate,
      DestinationCategoryEdit,
      DestinationCategoryList,
      DestinationCategoryShow
} from '../backend/destination_categories';
import {
      DestinationCreate,
      DestinationEdit,
      DestinationList,
      DestinationShow
} from '../backend/destinations';
import {
      ExpereinceCollectionCreate,
      ExpereinceCollectionEdit,
      ExperienceCollectionList,
      ExperienceCollectionShow
} from '../backend/experience_collections';
import {
      ExpereincePackageCreate,
      ExpereincePackageEdit,
      ExperiencePackageList,
      ExperiencePackageShow
} from '../backend/experience_packages';
import Profile from '../profile/Profile';
import ChangePassword from '../profile/ChangePassword';
import Setting from '../profile/Setting';
import SocialMedia from '../profile/SocialMedia';
import LogoSetting from '../profile/LogoSetting';
import { HotelList } from '../backend/hotels';
import {
      PackageEnquiryList,
      PackageEnquiryShow
} from '../backend/package_enquiries';
import {
      CustomizeEnquiryList,
      CustomizeEnquiryShow
} from '../backend/customizes';
import { PlanTripRequestList } from '../backend/plan_trip_requests';
import {
      DestinationCountryCreate,
      DestinationCountryEdit,
      DestinationCountryList,
      DestinationCountryOrder,
      DestinationCountryShow
} from '../backend/destination_countries';
import {
      DepartingCityCreate,
      DepartingCityEdit,
      DepartingCityList,
      DepartingCityOrder,
      DepartingCityShow
} from '../backend/departing_cities';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Category */}
                        <Route
                              path="/dashboard/categories"
                              element={<CategoryList />}
                        />
                        <Route
                              path="/dashboard/categories/create"
                              element={<CategoryCreate />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/edit"
                              element={<CategoryEdit />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/show"
                              element={<CategoryShow />}
                        />

                        {/* Project */}
                        <Route
                              path="/dashboard/projects"
                              element={<ProjectList />}
                        />
                        <Route
                              path="/dashboard/projects/create"
                              element={<ProjectCreate />}
                        />
                        <Route
                              path="/dashboard/projects/:projectId/edit"
                              element={<ProjectEdit />}
                        />
                        <Route
                              path="/dashboard/projects/:projectId/show"
                              element={<ProjectShow />}
                        />

                        {/* Service */}
                        <Route
                              path="/dashboard/services"
                              element={<ServiceList />}
                        />
                        <Route
                              path="/dashboard/services/create"
                              element={<ServiceCreate />}
                        />
                        <Route
                              path="/dashboard/services/:serviceId/edit"
                              element={<ServiceEdit />}
                        />
                        <Route
                              path="/dashboard/services/:serviceId/show"
                              element={<ServiceShow />}
                        />

                        {/* Faq */}
                        <Route path="/dashboard/faqs" element={<FaqList />} />
                        <Route
                              path="/dashboard/faqs/create"
                              element={<FaqCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/edit"
                              element={<FaqEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/show"
                              element={<FaqShow />}
                        />

                        {/* Faq Type */}
                        <Route
                              path="/dashboard/faqs/types"
                              element={<FaqTypeList />}
                        />
                        <Route
                              path="/dashboard/faqs/types/create"
                              element={<FaqTypeCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/edit"
                              element={<FaqTypeEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/show"
                              element={<FaqTypeShow />}
                        />

                        {/* Faq Request */}
                        <Route
                              path="/dashboard/faqs/requests"
                              element={<FaqRequestList />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/show"
                              element={<FaqRequestShow />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/edit"
                              element={<FaqRequestEdit />}
                        />

                        {/* Testimonial */}
                        <Route
                              path="/dashboard/testimonials"
                              element={<TestimonialList />}
                        />
                        <Route
                              path="/dashboard/testimonials/create"
                              element={<TestimonialCreate />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/edit"
                              element={<TestimonialEdit />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/show"
                              element={<TestimonialShow />}
                        />

                        {/* Plugin */}
                        <Route
                              path="/dashboard/plugins"
                              element={<PluginList />}
                        />
                        <Route
                              path="/dashboard/plugins/create"
                              element={<PluginCreate />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/edit"
                              element={<PluginEdit />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/show"
                              element={<PluginShow />}
                        />

                        {/* Job */}
                        <Route path="/dashboard/jobs" element={<JobList />} />
                        <Route
                              path="/dashboard/jobs/create"
                              element={<JobCreate />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/edit"
                              element={<JobEdit />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/show"
                              element={<JobShow />}
                        />

                        {/* Candidate */}
                        <Route
                              path="/dashboard/candidates"
                              element={<CandidateList />}
                        />
                        <Route
                              path="/dashboard/candidates/:candidateId/show"
                              element={<CandidateShow />}
                        />

                        {/* Complain */}
                        <Route
                              path="/dashboard/complains"
                              element={<ComplainList />}
                        />
                        <Route
                              path="/dashboard/complains/:complainId/show"
                              element={<ComplainShow />}
                        />

                        {/* Complain Type */}
                        <Route
                              path="/dashboard/complains/types"
                              element={<ComplainTypeList />}
                        />
                        <Route
                              path="/dashboard/complains/types/create"
                              element={<ComplainTypeCreate />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/edit"
                              element={<ComplainTypeEdit />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/show"
                              element={<ComplainTypeShow />}
                        />

                        {/* Subscriber */}
                        <Route
                              path="/dashboard/subscribers"
                              element={<SubscriberList />}
                        />
                        <Route
                              path="/dashboard/subscribers/create"
                              element={<SubscriberCreate />}
                        />
                        <Route
                              path="/dashboard/subscribers/:subscriberId/edit"
                              element={<SubscriberEdit />}
                        />

                        {/* Contact Request */}
                        <Route
                              path="/dashboard/contact/requests"
                              element={<ContactRequestList />}
                        />
                        <Route
                              path="/dashboard/contact/requests/:contactId/show"
                              element={<ContactRequestShow />}
                        />

                        {/* Catalogue */}
                        <Route
                              path="/dashboard/catalogues"
                              element={<CatalogueList />}
                        />
                        <Route
                              path="/dashboard/catalogues/create"
                              element={<CatalogueCreate />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/edit"
                              element={<CatalogueEdit />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/show"
                              element={<CatalogueShow />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Blog */}
                        <Route
                              path="/dashboard/blogs"
                              element={<BlogTable />}
                        />
                        <Route
                              path="/dashboard/blogs/create"
                              element={<BlogCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/edit"
                              element={<BlogEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/show"
                              element={<BlogShow />}
                        />

                        {/* Blog Category */}
                        <Route
                              path="/dashboard/blogs/categories/list"
                              element={<BlogCategoryList />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/create"
                              element={<BlogCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/edit"
                              element={<BlogCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/show"
                              element={<BlogCategoryShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />
                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        {/* Destination Country */}
                        <Route
                              path="/dashboard/destination_countries"
                              element={<DestinationCountryList />}
                        />
                        <Route
                              path="/dashboard/destination_countries/create"
                              element={<DestinationCountryCreate />}
                        />
                        <Route
                              path="/dashboard/destination_countries/:countryId/edit"
                              element={<DestinationCountryEdit />}
                        />
                        <Route
                              path="/dashboard/destination_countries/:countryId/show"
                              element={<DestinationCountryShow />}
                        />
                        <Route
                              path="/dashboard/destination_countries/manage/order"
                              element={<DestinationCountryOrder />}
                        />

                        {/* Destination City */}
                        <Route
                              path="/dashboard/departing_cities"
                              element={<DepartingCityList />}
                        />
                        <Route
                              path="/dashboard/departing_cities/create"
                              element={<DepartingCityCreate />}
                        />
                        <Route
                              path="/dashboard/departing_cities/:cityId/edit"
                              element={<DepartingCityEdit />}
                        />
                        <Route
                              path="/dashboard/departing_cities/manage/order"
                              element={<DepartingCityOrder />}
                        />
                        <Route
                              path="/dashboard/departing_cities/:cityId/show"
                              element={<DepartingCityShow />}
                        />

                        {/* Destination Category */}
                        <Route
                              path="/dashboard/destination_categories"
                              element={<DestinationCategoryList />}
                        />
                        <Route
                              path="/dashboard/destination_categories/create"
                              element={<DestinationCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/destination_categories/:categoryId/edit"
                              element={<DestinationCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/destination_categories/:categoryId/show"
                              element={<DestinationCategoryShow />}
                        />

                        {/* Destination */}
                        <Route
                              path="/dashboard/destinations"
                              element={<DestinationList />}
                        />
                        <Route
                              path="/dashboard/destinations/create"
                              element={<DestinationCreate />}
                        />
                        <Route
                              path="/dashboard/destinations/:destinationId/edit"
                              element={<DestinationEdit />}
                        />
                        <Route
                              path="/dashboard/destinations/:destinationId/show"
                              element={<DestinationShow />}
                        />

                        {/* Package */}
                        <Route
                              path="/dashboard/packages"
                              element={<PackageList />}
                        />
                        <Route
                              path="/dashboard/packages/create"
                              element={<PackageCreate />}
                        />
                        <Route
                              path="/dashboard/packages/:packageId/edit"
                              element={<PackageEdit />}
                        />
                        <Route
                              path="/dashboard/packages/:packageId/show"
                              element={<PackageShow />}
                        />

                        {/* Package Type */}
                        <Route
                              path="/dashboard/hotel_categories"
                              element={<HotelCategoryList />}
                        />
                        <Route
                              path="/dashboard/hotel_categories/create"
                              element={<HotelCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/hotel_categories/:typeId/edit"
                              element={<HotelCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/hotel_categories/:typeId/show"
                              element={<HotelCategoryShow />}
                        />

                        {/* Experience */}
                        <Route
                              path="/dashboard/experiences"
                              element={<ExperienceList />}
                        />
                        <Route
                              path="/dashboard/experiences/create"
                              element={<ExperienceCreate />}
                        />
                        <Route
                              path="/dashboard/experiences/:experienceId/edit"
                              element={<ExperienceEdit />}
                        />
                        <Route
                              path="/dashboard/experiences/:experienceId/show"
                              element={<ExperienceShow />}
                        />

                        {/* Experience Collection */}
                        <Route
                              path="/dashboard/experience_collections"
                              element={<ExperienceCollectionList />}
                        />
                        <Route
                              path="/dashboard/experience_collections/create"
                              element={<ExpereinceCollectionCreate />}
                        />
                        <Route
                              path="/dashboard/experience_collections/:collectionId/edit"
                              element={<ExpereinceCollectionEdit />}
                        />
                        <Route
                              path="/dashboard/experience_collections/:collectionId/show"
                              element={<ExperienceCollectionShow />}
                        />

                        {/* Experience Package */}
                        <Route
                              path="/dashboard/experience_packages"
                              element={<ExperiencePackageList />}
                        />
                        <Route
                              path="/dashboard/experience_packages/create"
                              element={<ExpereincePackageCreate />}
                        />
                        <Route
                              path="/dashboard/experience_packages/:packageId/edit"
                              element={<ExpereincePackageEdit />}
                        />
                        <Route
                              path="/dashboard/experience_packages/:packageId/show"
                              element={<ExperiencePackageShow />}
                        />

                        {/* Country */}
                        <Route
                              path="/dashboard/countries"
                              element={<CountryList />}
                        />
                        <Route
                              path="/dashboard/countries/create"
                              element={<CountryCreate />}
                        />
                        <Route
                              path="/dashboard/countries/:countryId/edit"
                              element={<CountryEdit />}
                        />
                        <Route
                              path="/dashboard/countries/:countryId/show"
                              element={<CountryShow />}
                        />

                        {/* Vehicles */}
                        <Route
                              path="/dashboard/vehicles"
                              element={<VehicleList />}
                        />
                        <Route
                              path="/dashboard/vehicles/create"
                              element={<VehicleCreate />}
                        />
                        <Route
                              path="/dashboard/vehicles/:vehicleId/edit"
                              element={<VehicleEdit />}
                        />
                        <Route
                              path="/dashboard/vehicles/:vehicleId/show"
                              element={<VehicleShow />}
                        />

                        {/* Hotel */}
                        <Route
                              path="/dashboard/hotels"
                              element={<HotelList />}
                        />

                        {/* Package Enquiry */}
                        <Route
                              path="/dashboard/package_enquiries"
                              element={<PackageEnquiryList />}
                        />
                        <Route
                              path="/dashboard/package_enquiries/:enquiryId/show"
                              element={<PackageEnquiryShow />}
                        />

                        {/* Customize Trip Requsts */}
                        <Route
                              path="/dashboard/customize_trip_requests"
                              element={<CustomizeEnquiryList />}
                        />
                        <Route
                              path="/dashboard/customize_trip_requests/:enquiryId/show"
                              element={<CustomizeEnquiryShow />}
                        />

                        {/* Plan Trip Request */}
                        <Route
                              path="/dashboard/plan_trip_requests"
                              element={<PlanTripRequestList />}
                        />
                        <Route path="/dashboard/plan_trip_requests/:enquiryId" />

                        {/* Profile */}
                        <Route
                              path="/dashboard/profile"
                              element={<Profile />}
                        />
                        <Route
                              path="/dashboard/change-password"
                              element={<ChangePassword />}
                        />
                        <Route
                              path="/dashboard/settings"
                              element={<Setting />}
                        />
                        <Route
                              path="/dashboard/social-media-settings"
                              element={<SocialMedia />}
                        />
                        <Route
                              path="/dashboard/logo-settings"
                              element={<LogoSetting />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
