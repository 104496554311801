import React, { useEffect, useState } from 'react';
import ProfileLayout from '../ProfileLayout';
import ProfileTitle from '../../components/profile/ProfileTitle';
import { FormButton, FormInput } from '../../components/form';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';

const SocialMedia = () => {
      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);

      const [facebookLink, setFacebookLink] = useState('');
      const [instagramLink, setInstagramLink] = useState('');
      const [twitterLink, setTwitterLink] = useState('');
      const [linkedin, setLinkedin] = useState('');
      const [whatsapp, setWhatsapp] = useState('');
      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/social_media_settings`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setFacebookLink(response.data.settings.facebook_link);
                        setInstagramLink(response.data.settings.instagram_link);
                        setTwitterLink(response.data.settings.twitter_link);
                        setLinkedin(response.data.settings.linkedin);
                        setWhatsapp(response.data.settings.whatsapp);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  facebook_link: facebookLink,
                  instagram_link: instagramLink,
                  twitter_link: twitterLink,
                  whatsapp: whatsapp,
                  linkedin: linkedin
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/social_media_settings`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result == 'error') {
                              if (response.data.message.facebook_link) {
                                    toast.error(
                                          response.data.message.facebook_link
                                    );
                              }
                              if (response.data.message.instagram_link) {
                                    toast.error(
                                          response.data.message.instagram_link
                                    );
                              }
                              if (response.data.message.twitter_link) {
                                    toast.error(
                                          response.data.message.twitter_link
                                    );
                              }
                              if (response.data.message.whatsapp) {
                                    toast.error(response.data.message.whatsapp);
                              }
                              if (response.data.message.linkedin) {
                                    toast.error(response.data.message.linkedin);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <ProfileLayout>
                        <ProfileTitle title="General Setting" />
                        <Form className="row gap-3" onSubmit={handleFormSubmit}>
                              <FormInput
                                    title="Facebook Link"
                                    value={facebookLink}
                                    required={true}
                                    handleChange={setFacebookLink}
                              />
                              <FormInput
                                    title="Instagram Link"
                                    value={instagramLink}
                                    required={true}
                                    handleChange={setInstagramLink}
                              />
                              <FormInput
                                    title="Twitter Link"
                                    value={twitterLink}
                                    required={true}
                                    handleChange={setTwitterLink}
                              />
                              <FormInput
                                    title="Whatsapp Link"
                                    value={whatsapp}
                                    required={true}
                                    handleChange={setWhatsapp}
                              />
                              <FormInput
                                    title="Linkedin Link"
                                    value={linkedin}
                                    required={true}
                                    handleChange={setLinkedin}
                              />
                              <div className="col-md-12">
                                    <FormButton
                                          loading={loading}
                                          title="Update"
                                    />
                              </div>
                        </Form>
                  </ProfileLayout>
            </>
      );
};

export default SocialMedia;
