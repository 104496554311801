import React from 'react';

const DestinationIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        where_to_vote
                  </span>
            </>
      );
};

export default DestinationIcon;
