import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormImage,
      FormInput,
      FormSelect,
      FormStatusSelect
} from '../../components/form';

const types = [
      {
            id: 'Five Image Layout',
            title: 'Five Image Layout'
      },
      {
            id: 'Three Image Layout',
            title: 'Three Image Layout'
      }
];

const ExpereinceCollectionEdit = () => {
      const { collectionId } = useParams();
      const [categories, setCategories] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [title, setTitle] = useState('');
      const [type, setType] = useState('');
      const [category, setCategory] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [secondImage, setSecondImage] = useState('');
      const [secondImageUrl, setSecondImageUrl] = useState('');
      const [thirdImage, setThirdImage] = useState('');
      const [thirdImageUrl, setThirdImageUrl] = useState('');
      const [fourthImage, setFourthImage] = useState('');
      const [fourthImageUrl, setFourthImageUrl] = useState(null);
      const [fifthImage, setFifthImage] = useState('');
      const [fifthImageUrl, setFifthImageUrl] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_collections/${collectionId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTitle(response.data.collection.title);
                              setType(response.data.collection.type);
                              setSecondImageUrl(
                                    response.data.collection.second_image
                              );
                              setThirdImageUrl(
                                    response.data.collection.third_image
                              );
                              setFourthImageUrl(
                                    response.data.collection.fourth_image
                              );
                              setFifthImageUrl(
                                    response.data.collection.fifth_image
                              );
                              setStatus(response.data.collection.status);
                              setImageUrl(response.data.collection.image);
                              setCategory(
                                    response.data.collection.experience_id
                              );
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_collections/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  type: type,
                  status: status,
                  category: category,
                  first_image: image,
                  second_image: secondImage,
                  third_image: thirdImage,
                  fourth_image: fourthImage,
                  fifth_image: fifthImage
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_collections/${collectionId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.first_image) {
                                    toast.error(
                                          response.data.message.first_image
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.second_image) {
                                    toast.error(
                                          response.data.message.second_image
                                    );
                              }
                              if (response.data.message.third_image) {
                                    toast.error(
                                          response.data.message.third_image
                                    );
                              }
                              if (response.data.message.fourth_image) {
                                    toast.error(
                                          response.data.message.fourth_image
                                    );
                              }
                              if (response.data.message.fifth_image) {
                                    toast.error(
                                          response.data.message.fifth_image
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col>
                                    <Card>
                                          <CardHeader title="Create Experience Collection">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/experience_collections"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                            value={title}
                                                      />
                                                      <FormSelect
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Design Type"
                                                            options={types}
                                                            classes={4}
                                                            selected={type}
                                                            required={true}
                                                            setData={setType}
                                                      />

                                                      <FormSelect
                                                            options={categories}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            selected={category}
                                                            classes={4}
                                                            title="Category"
                                                            setData={
                                                                  setCategory
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            imageUrl={imageUrl}
                                                      />
                                                      {type ===
                                                            'Three Image Layout' ||
                                                      type ===
                                                            'Five Image Layout' ? (
                                                            <>
                                                                  <FormImage
                                                                        title="Second Image"
                                                                        setImage={
                                                                              setSecondImage
                                                                        }
                                                                        setImageUrl={
                                                                              setSecondImageUrl
                                                                        }
                                                                        imageUrl={
                                                                              secondImageUrl
                                                                        }
                                                                  />
                                                                  <FormImage
                                                                        title="Third Image"
                                                                        setImage={
                                                                              setThirdImage
                                                                        }
                                                                        setImageUrl={
                                                                              setThirdImageUrl
                                                                        }
                                                                        imageUrl={
                                                                              thirdImageUrl
                                                                        }
                                                                  />
                                                            </>
                                                      ) : null}
                                                      {type ===
                                                            'Five Image Layout' && (
                                                            <>
                                                                  <FormImage
                                                                        title="Fourth Image"
                                                                        setImage={
                                                                              setFourthImage
                                                                        }
                                                                        setImageUrl={
                                                                              setFourthImageUrl
                                                                        }
                                                                        imageUrl={
                                                                              fourthImageUrl
                                                                        }
                                                                  />
                                                                  <FormImage
                                                                        title="Fifth Image"
                                                                        setImage={
                                                                              setFifthImage
                                                                        }
                                                                        setImageUrl={
                                                                              setFifthImageUrl
                                                                        }
                                                                        imageUrl={
                                                                              fifthImageUrl
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ExpereinceCollectionEdit;
