import React from 'react';

const CustomizeTripIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        mode_of_travel
                  </span>
            </>
      );
};

export default CustomizeTripIcon;
