import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Dropdown, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import PackageBox from '../../components/backend/PackageBox';

const PackageList = () => {
      const [loading, setLoading] = useState(false);
      const [packages, setPackages] = useState([]);
      const [totalPackage, setTotalPacakge] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setPackages(response.data.packages);
                        setTotalPacakge(response.data.totalPackage);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        setLoading(false);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setPackages([]);
                        setPackages(response.data.packages);
                        setTotalPacakge(response.data.totalPackage);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Package List">
                                          <div className="d-flex">
                                                <Link
                                                      to="/dashboard/packages/create"
                                                      className="btn btn-link me-2"
                                                >
                                                      Add New
                                                </Link>
                                          </div>
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          totalItems={totalPackage}
                                          getData={getData}
                                          currentPage={currentPage}
                                          perPages={perPages}
                                    >
                                          <PackageBox
                                                loading={loading}
                                                setLoading={setLoading}
                                                packages={packages}
                                                loadData={loadData}
                                                setPackages={setPackages}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PackageList;
