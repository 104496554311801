import React from 'react';
import { Col, Form } from 'react-bootstrap';
import {
      FormGroupBody,
      FormGroupInput,
      FormGroupSection,
      FormGroupUpload,
      FormImageContainer,
      FormImageGallery,
      FormImageRemove
} from './styles';
import { UploadIcon } from '../../icons';
import { type } from '@testing-library/user-event/dist/type';

const FormImage = ({
      title,
      required,
      setImage,
      setImageUrl,
      imageUrl,
      grid,
      imageType,
      color,
      classes,
      videoType
}) => {
      const handleImage = (file) => {
            setImage(file);
            if (imageType) {
                  if (videoType === 'video') {
                        setImageUrl(URL.createObjectURL(file));
                  } else {
                        setImageUrl('/images/icons/pdf.png');
                  }
            } else {
                  setImageUrl(URL.createObjectURL(file));
            }
      };

      const handleRemoveImage = () => {
            setImage('');
            setImageUrl('');
      };

      return (
            <>
                  <Col lg={classes ? classes : 4}>
                        <Form.Group>
                              <FormGroupBody>
                                    <Form.Label>
                                          {title}{' '}
                                          {required && (
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          )}
                                    </Form.Label>
                                    <FormGroupSection>
                                          <FormGroupUpload
                                                role="button"
                                                tabIndex="0"
                                          >
                                                <FormGroupInput
                                                      accept={`${
                                                            imageType
                                                                  ? imageType
                                                                  : 'image/*'
                                                      }`}
                                                      onChange={(event) =>
                                                            handleImage(
                                                                  event.target
                                                                        .files[0]
                                                            )
                                                      }
                                                      type="file"
                                                      autoComplete="off"
                                                      tabIndex="-1"
                                                />
                                                <UploadIcon />
                                                <p>
                                                      <span className="text-accent font-semibold">
                                                            Upload an image or
                                                            drag and drop{' '}
                                                      </span>

                                                      <br />
                                                      {imageType ? (
                                                            <span>
                                                                  {imageType}
                                                            </span>
                                                      ) : (
                                                            <span>
                                                                  PNG, JPG
                                                            </span>
                                                      )}
                                                </p>
                                          </FormGroupUpload>
                                    </FormGroupSection>

                                    {imageUrl && (
                                          <FormImageGallery
                                                style={{
                                                      gridTemplateColumns:
                                                            grid &&
                                                            `repeat(${grid}, 1fr)`,
                                                      backgroundColor: color,
                                                      borderRadius: '5px'
                                                }}
                                          >
                                                <FormImageContainer>
                                                      {videoType === 'video' ? (
                                                            <video
                                                                  src={imageUrl}
                                                                  className="w-100"
                                                                  autoPlay={
                                                                        true
                                                                  }
                                                                  muted={true}
                                                                  loop={true}
                                                            ></video>
                                                      ) : (
                                                            <img
                                                                  src={imageUrl}
                                                            />
                                                      )}
                                                      <FormImageRemove
                                                            onClick={() =>
                                                                  handleRemoveImage()
                                                            }
                                                      >
                                                            Remove
                                                      </FormImageRemove>
                                                </FormImageContainer>
                                          </FormImageGallery>
                                    )}
                              </FormGroupBody>
                        </Form.Group>
                  </Col>
            </>
      );
};

export default FormImage;
