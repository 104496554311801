import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import { ItemShow } from '../../components/form';

const ServiceShow = () => {
      const { serviceId } = useParams();
      const [loading, setLoading] = useState(false);
      const [service, setService] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/services/${serviceId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setService(response.data.service);
                        }
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>
                                                Service Detail
                                          </Card.Title>
                                          <Link
                                                to="/dashboard/services"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <Row>
                                                <Col md={6}>
                                                      <Card>
                                                            <Card.Body>
                                                                  {service?.image && (
                                                                        <img
                                                                              className='w-100'
                                                                              src={
                                                                                    service?.image
                                                                              }
                                                                              alt={
                                                                                    service?.title
                                                                              }
                                                                        />
                                                                  )}
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: service.summary
                                                                        }}
                                                                  ></div>
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: service.description
                                                                        }}
                                                                  ></div>
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="SEO Title"
                                                                              value={
                                                                                    service.seo_title
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="SEO Keyword"
                                                                              value={`${service.seo_keyword}`}
                                                                        />
                                                                        <ItemShow
                                                                              title="SEO Description"
                                                                              value={
                                                                                    service.seo_description
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                            </Card.Body>
                                                      </Card>
                                                </Col>
                                                <Col md={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Service Name"
                                                                  value={
                                                                        service.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By"
                                                                  value={`${service.user_name}`}
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        service.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created Date"
                                                                  value={
                                                                        service.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ServiceShow;
