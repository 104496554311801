import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DashboardItem = ({ title, total, icon, link }) => {
      return (
            <>
                  <Col lg={3} md={6} sm={6}>
                        <Link to={link} className="card card-animate">
                              <Card.Body>
                                    <div className="d-flex align-items-center">
                                          <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                      {title}
                                                </p>
                                          </div>
                                          <div className="flex-shrink-0">
                                                <h5 className="text-success fs-14 mb-0">
                                                      <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                                                </h5>
                                          </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-3">
                                          <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                                                      {total}
                                                </h4>
                                          </div>
                                          <div className="avatar-sm flex-shrink-0">
                                                <span className="avatar-title bg-soft-success rounded fs-3 p-0">
                                                      {icon}
                                                </span>
                                          </div>
                                    </div>
                              </Card.Body>
                        </Link>
                  </Col>
            </>
      );
};

export default DashboardItem;
