import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import ComplainListBox from '../../components/backend/ComplainListBox';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';

const ComplainList = () => {
      const [loading, setLoading] = useState(false);
      const [complains, setComplains] = useState([]);
      const [totalComplains, setTotalComplains] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchComplain = async () => {
            setLoading(true);

            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/complains?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`
                        }
                  }
            )
                  .then((response) => {
                        setComplains(response.data.complains);
                        setTotalComplains(response.data.totalComplains);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchComplain();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/complains?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        setComplains(response.data.brands);
                        setTotalComplains(response.data.totalComplains);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card className="mt-4">
                                    <CardHeader title="Complains List" />
                                    <CardBody
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          setSearch={setSearch}
                                          currentPage={currentPage}
                                          totalItems={totalComplains}
                                          getData={getData}
                                    >
                                          <ComplainListBox
                                                loading={loading}
                                                setLoading={setLoading}
                                                complains={complains}
                                                setComplains={setComplains}
                                          />
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ComplainList;
