import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';

const ContactRequestShow = () => {
      const { contactId } = useParams();
      const [contact, setContact] = useState([]);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/contact_forms/${contactId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setContact(response.data.contact);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Contact Details">
                                          <LinkButton
                                                link="/dashboard/contact/requests"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Subject"
                                                                  value={
                                                                        contact.subject
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Full Name"
                                                                  value={
                                                                        contact.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email Address"
                                                                  value={
                                                                        contact.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Phone"
                                                                  value={
                                                                        contact.phone
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        contact.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Message"
                                                                  value={
                                                                        contact.message
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ContactRequestShow;
