import { Accordion, Row } from 'react-bootstrap';
import FormInput from '../../form/FormInput';
import FormTextarea from '../../form/FormTextarea';
import FormImage from '../../form/FormImage';

const FormSeo = ({
      setSeoTitle,
      title,
      setSeoDescription,
      description,
      setSeoImage,
      setSeoKeyword,
      keyword,
      setSeoImageUrl,
      seoImageUrl
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>Manage Seo</Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <FormInput
                                                value={title}
                                                title="Seo Title"
                                                handleChange={setSeoTitle}
                                          />
                                          <FormInput
                                                value={keyword}
                                                title="Seo Keyword"
                                                handleChange={setSeoKeyword}
                                          />
                                          <FormTextarea
                                                value={description}
                                                title="Seo Description"
                                                handleChange={setSeoDescription}
                                          />
                                          {/* <FormImage
                                                title="Seo Image"
                                                setImage={setSeoImage}
                                                setImageUrl={setSeoImageUrl}
                                                imageUrl={seoImageUrl}
                                          /> */}
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default FormSeo;
