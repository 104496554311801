import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import {
      FormButton,
      FormInput,
      FormSelect,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';
import LinkButton from '../../components/common/LinkButton';

const PluginCreate = () => {
      const [loadingData, setLoadingData] = useState(false);

      const [title, setTitle] = useState('');
      const [code, setCode] = useState('');
      const [type, setType] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const options = [
            {
                  id: 'Body',
                  title: 'Body'
            },
            {
                  id: 'Header',
                  title: 'Header'
            }
      ];

      const token = JSON.parse(localStorage.getItem('token'));

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  code: code,
                  type: type,
                  status: status
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/plugins`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.code) {
                                    toast.error(response.data.message.code);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create New Plugin">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/plugins"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={12}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormSelect
                                                            loading={
                                                                  loadingData
                                                            }
                                                            title="Plugin Type"
                                                            required={true}
                                                            options={options}
                                                            setData={setType}
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={6}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Code"
                                                            required={true}
                                                            handleChange={
                                                                  setCode
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <FormButton
                                                                  title="Submit"
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default PluginCreate;
