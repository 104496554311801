import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import { ItemShow } from '../../components/form';
import ProjectSlider from './ProjectSlider';

const ProjectShow = () => {
      const { projectId } = useParams();
      const [loading, setLoading] = useState(false);
      const [project, setProject] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/projects/${projectId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProject(response.data.project);
                        }
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>
                                                Project Detail
                                          </Card.Title>
                                          <Link
                                                to="/dashboard/projects"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <Row>
                                                <Col md={6}>
                                                      <Card>
                                                            <Card.Body>
                                                                  <div className="text-center">
                                                                        {!loading ? (
                                                                              <ProjectSlider
                                                                                    image={
                                                                                          project.image
                                                                                    }
                                                                                    images={
                                                                                          project.images
                                                                                    }
                                                                              />
                                                                        ) : null}
                                                                  </div>
                                                                  <p>
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: project.description
                                                                              }}
                                                                        ></div>
                                                                  </p>
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="SEO Title"
                                                                              value={
                                                                                    project.seo_title
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="SEO Keyword"
                                                                              value={`${project.seo_keyword}`}
                                                                        />
                                                                        <ItemShow
                                                                              title="SEO Description"
                                                                              value={
                                                                                    project.seo_description
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                            </Card.Body>
                                                      </Card>
                                                </Col>
                                                <Col md={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Project Name"
                                                                  value={
                                                                        project.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Location"
                                                                  value={
                                                                        project.location
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Website"
                                                                  value={
                                                                        project.website
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        project.project_date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By"
                                                                  value={`${project.user_name}`}
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        project.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created Date"
                                                                  value={
                                                                        project.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProjectShow;
