import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormImage,
      FormInput,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';

const TestimonialEdit = () => {
      const { testimonialId } = useParams();

      const [name, setName] = useState('');
      const [packageName, setPackageName] = useState('');
      const [rating, setRating] = useState('');
      const [link, setLink] = useState('');
      const [message, setMessage] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonialId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setName(response.data.testimonial.name);
                              setPackageName(
                                    response.data.testimonial.package_name
                              );
                              setMessage(response.data.testimonial.message);
                              setRating(response.data.testimonial.rating);
                              setLink(response.data.testimonial.link);
                              setImageUrl(response.data.testimonial.image);
                              setStatus(response.data.testimonial.status);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  name: name,
                  package_name: packageName,
                  rating: rating,
                  message: message,
                  image: image,
                  link: link,
                  status: status
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/testimonials/${testimonialId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.package_name) {
                                    toast.error(
                                          response.data.message.package_name
                                    );
                              }
                              if (response.data.message.rating) {
                                    toast.error(response.data.message.rating);
                              }
                              if (response.data.message.link) {
                                    toast.error(response.data.message.link);
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Testimonial">
                                                <LinkButton
                                                      link="/dashboard/testimonials"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Person Name"
                                                            required={true}
                                                            value={name}
                                                            classes={8}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            selected={status}
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Package Name"
                                                            required={true}
                                                            classes={4}
                                                            value={packageName}
                                                            handleChange={
                                                                  setPackageName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Rating"
                                                            required={true}
                                                            classes={4}
                                                            value={rating}
                                                            handleChange={
                                                                  setRating
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Link"
                                                            required={true}
                                                            classes={4}
                                                            value={link}
                                                            handleChange={
                                                                  setLink
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Message"
                                                            required={true}
                                                            value={message}
                                                            handleChange={
                                                                  setMessage
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default TestimonialEdit;
