import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Progress from 'react-progress-2';
import { Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import CardBody from '../../components/common/CardBody';
import ProductImage from '../../components/common/ProductImage';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';

const CustomizeEnquiryList = () => {
      const [loading, setLoading] = useState(false);
      const [enquiries, setEnquiries] = useState([]);
      const [totalEnquiries, setTotalEnquiries] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customizes?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        setEnquiries(response.data.enquiries);
                        setTotalEnquiries(response.data.totalEnquiries);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customizes?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setEnquiries(response.data.packages);
                              setTotalEnquiries(response.data.totalEnquiries);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleDelete = async (blog) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customizes/${blog.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setEnquiries((packages) =>
                                    packages.filter(
                                          (item) => item.id !== blog.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Customize Trip Request " />
                                    <CardBody
                                          setLimit={setLimit}
                                          perPages={perPages}
                                          totalItems={totalEnquiries}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                    >
                                          <table className="table table-bordered table-hover">
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>
                                                                  Contact
                                                                  Details
                                                            </th>
                                                            <th>
                                                                  Customize Trip
                                                            </th>
                                                            <th>Duration</th>
                                                            <th width="120px">
                                                                  Action
                                                            </th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            enquiries?.length >
                                                            0 ? (
                                                                  enquiries?.map(
                                                                        (
                                                                              blog,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      ++index
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            blog.name
                                                                                                      }
                                                                                                      category={`Contact: ${blog.phone_number}`}
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            blog.leaving_from
                                                                                                      }
                                                                                                      category={`To: ${blog.destination}`}
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            blog.duration
                                                                                                      }
                                                                                                      category={`On: ${blog.depart}`}
                                                                                                />
                                                                                          </td>
                                                                                          <td className="text-center">
                                                                                                <ViewButton
                                                                                                      link={`/dashboard/customize_trip_requests/${blog.slug}/show`}
                                                                                                />
                                                                                                <DeleteButton
                                                                                                      item={
                                                                                                            blog
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      setLoading={
                                                                                                            setLoading
                                                                                                      }
                                                                                                      handleDelete={
                                                                                                            handleDelete
                                                                                                      }
                                                                                                />
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Enquiry
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              Loading...
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </table>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CustomizeEnquiryList;
