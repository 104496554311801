import React from 'react';
import ProductImage from '../../common/ProductImage';
import ViewButton from '../../common/ViewButton';
import DeleteButton from '../../common/DeleteButton';
import axios from 'axios';
import toast from 'react-hot-toast';

const ComplainListBox = ({
      loading,
      setLoading,
      complains,
      setComplains,
      fetchData
}) => {
      const token = JSON.parse(localStorage.getItem('token'));

      const handleDelete = async (complain) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/complains/${complain.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setComplains((complains) =>
                                    complains.filter(
                                          (item) => item.id !== complain.id
                                    )
                              );
                              fetchData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <thead>
                        <tr>
                              <th>#</th>
                              <th>Products</th>
                              <th>By </th>
                              <th>Complain</th>
                              <th>Purchase</th>
                              <th>Apply On</th>
                              <th width="120px">Action</th>
                        </tr>
                  </thead>
                  <tbody>
                        {!loading ? (
                              complains.length > 0 ? (
                                    complains.map((complain, index) => (
                                          <>
                                                <tr key={index}>
                                                      <td className="text-center">
                                                            {complain.index}
                                                      </td>
                                                      <td>
                                                            <ProductImage
                                                                  title={
                                                                        complain.product_name
                                                                  }
                                                                  category={
                                                                        complain.category
                                                                  }
                                                            />
                                                      </td>
                                                      <td>
                                                            <ProductImage
                                                                  title={
                                                                        complain.name
                                                                  }
                                                                  category={
                                                                        complain.phone_number
                                                                  }
                                                            />
                                                      </td>
                                                      <td>
                                                            {
                                                                  complain.complain_type
                                                            }
                                                      </td>
                                                      <td>
                                                            {
                                                                  complain.date_of_purchase
                                                            }
                                                      </td>
                                                      <td>{complain.date}</td>
                                                      <td className="text-center">
                                                            <ViewButton
                                                                  link={`/dashboard/complains/${complain.slug}/show`}
                                                            />
                                                            <DeleteButton
                                                                  item={
                                                                        complain
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  setLoading={
                                                                        setLoading
                                                                  }
                                                                  handleDelete={
                                                                        handleDelete
                                                                  }
                                                            />
                                                      </td>
                                                </tr>
                                          </>
                                    ))
                              ) : (
                                    <tr>
                                          <td
                                                colSpan={7}
                                                className="text-center"
                                          >
                                                No Complain Found
                                          </td>
                                    </tr>
                              )
                        ) : (
                              <>
                                    <tr>
                                          <td
                                                colSpan={7}
                                                className="text-center"
                                          >
                                                Loading...
                                          </td>
                                    </tr>
                              </>
                        )}
                  </tbody>
            </>
      );
};

export default ComplainListBox;
