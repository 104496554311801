import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';

import { LinkButton } from '../../styles/components/common/Button';
import Input from '../../components/form/FormInput';
import FormSelect from '../../components/form/FormSelect';
import FormSeo from '../../components/common/FormSeo';
import MultipleImage from '../../components/form/MultipleImage';
import FormButton from '../../components/form/FormButton';
import FormStatusSelect from '../../components/form/FormStatusSelect';
import FormImage from '../../components/form/FormImage';
import ExtraDetail from '../../components/common/ExtraDetail';
import { FormDescription } from '../../components/form';
import { durations } from '../../data/durations';

const types = [
      {
            id: 'Single',
            title: 'Single'
      },
      {
            id: 'Group',
            title: 'Group'
      }
];

const PackageEdit = () => {
      const { packageId } = useParams();
      const navigate = useNavigate();
      const [destionations, setDestinations] = useState([]);
      const [destinationCategories, setDestinationCategories] = useState([]);
      const [vehicles, setVehicles] = useState([]);
      const [hotelCategories, setHotelCategories] = useState([]);
      const [loadingData, setLoadingData] = useState(false);
      const [packageType, setPackageType] = useState('');

      const [loading, setLoading] = useState('');
      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [destinationCategory, setDestinationCategory] = useState([]);
      const [hotelCategory, setHotelCategory] = useState([]);
      const [groupSize, setGroupSize] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [bannerImage, setBannerImage] = useState('');
      const [bannerImageUrl, setBannerImageUrl] = useState(null);
      const [imagesUrl, setImagesUrl] = useState([]);
      const [description, setDescription] = useState('');
      const [productImages, setProductImages] = useState([]);
      const [price, setPrice] = useState('');
      const [offerPrice, setOfferPrice] = useState('');
      const [priceType, setPriceType] = useState('');
      const [duration, setDuration] = useState('');
      const [destination, setDestination] = useState('');
      const [location, setLocation] = useState('');
      const [vehicle, setVehicle] = useState('');
      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);
      const [images, setImages] = useState([]);
      const [imageFiles, setImageFiles] = useState([]);
      const [status, setStatus] = useState('');

      const [itinearies, setItinearies] = useState([
            { title: '', description: '' }
      ]);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setHotelCategories(response.data.categories);
                              setDestinationCategories(
                                    response.data.destinationCategories
                              );
                              setVehicles(response.data.vehicles);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages/${packageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTitle(response.data.package.title);
                              setSlug(response.data.package.slug);
                              setDuration(response.data.package.duration);
                              setLocation(response.data.package.location);
                              setGroupSize(response.data.package.group_size);
                              setPrice(response.data.package.price);
                              setOfferPrice(response.data.package.offer_price);
                              setHotelCategory(response.data.package.type_id);
                              setPriceType(response.data.package.price_type);
                              setDestination(
                                    response.data.package.destination_id
                              );
                              setDescription(response.data.package.description);
                              setDestinationCategory(
                                    response.data.package
                                          .destination_category_id
                              );
                              setLocation(response.data.package.location);
                              setVehicle(response.data.package.vehicle_id);
                              setStatus(response.data.package.status);
                              setImageUrl(response.data.package.image);
                              setBannerImageUrl(
                                    response.data.package.banner_image
                              );
                              setSeoTitle(response.data.package.seo_title);
                              setSeoKeyword(response.data.package.seo_keyword);
                              setSeoDescription(
                                    response.data.package.seo_description
                              );
                              setProductImages(response.data.package.images);
                              setItinearies(response.data.package.itineraries);
                              setPackageType(
                                    response.data.package.package_type
                              );
                              if (
                                    response.data.package
                                          .destination_category_id
                              ) {
                                    loadSubCategory(
                                          response.data.package
                                                .destination_category_id
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/packages/${updatedSlug}/edit`);
                  }
            };

            const data = {
                  title: title,
                  slug: slug,
                  duration: duration,
                  group_size: groupSize,
                  hotel_category: hotelCategory,
                  price: price,
                  offer_price: offerPrice,
                  price_type: priceType,
                  destination_category: destinationCategory,
                  destination: destination,
                  location: location,
                  vehicle: vehicle,
                  status: status,
                  itinearies: itinearies,
                  image: image,
                  images: imageFiles,
                  banner_image: bannerImage,
                  seo_title: seoTitle,
                  description: description,
                  seo_description: seoDescription,
                  seo_keyword: seoKeyword,
                  package_type: packageType
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages/${packageId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.duration) {
                                    toast.error(response.data.message.duration);
                              }
                              if (response.data.message.group_size) {
                                    toast.error(
                                          response.data.message.group_size
                                    );
                              }

                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.location) {
                                    toast.error(response.data.message.location);
                              }
                              if (response.data.message.price) {
                                    toast.error(response.data.message.price);
                              }
                              if (response.data.message.offer_price) {
                                    toast.error(
                                          response.data.message.offer_price
                                    );
                              }
                              if (response.data.message.price_type) {
                                    toast.error(
                                          response.data.message.price_type
                                    );
                              }
                              if (response.data.message.destination_category) {
                                    toast.error(
                                          response.data.message
                                                .destination_category
                                    );
                              }
                              if (response.data.message.destination) {
                                    toast.error(
                                          response.data.message.destination
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.vehicle) {
                                    toast.error(response.data.message.vehicle);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.banner_image) {
                                    toast.error(
                                          response.data.message.banner_image
                                    );
                              }
                              if (response.data.message.package_type) {
                                    toast.error(
                                          response.data.message.package_type
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const loadSubCategory = async (value) => {
            setDestinationCategory(value);

            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages/destinations/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader>
                                                <CardTitle>
                                                      Edit Package
                                                </CardTitle>
                                                <CardLinks>
                                                      <LinkButton to="/dashboard/packages">
                                                            Back
                                                      </LinkButton>
                                                </CardLinks>
                                          </CardHeader>

                                          <Card.Body>
                                                {!loadingData ? (
                                                      <Row className="g-3">
                                                            <Input
                                                                  title="Package Name"
                                                                  classes={8}
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setTitle
                                                                  }
                                                                  value={title}
                                                            />
                                                            <Input
                                                                  title="Package Slug"
                                                                  classes={4}
                                                                  required={
                                                                        true
                                                                  }
                                                                  value={slug}
                                                                  handleChange={
                                                                        setSlug
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Package Type"
                                                                  classes={4}
                                                                  selected={
                                                                        packageType
                                                                  }
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        setPackageType
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        types
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Destination Category"
                                                                  classes={4}
                                                                  selected={
                                                                        destinationCategory
                                                                  }
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        loadSubCategory
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        destinationCategories
                                                                  }
                                                            />
                                                            {destionations.length >
                                                                  0 && (
                                                                  <FormSelect
                                                                        title="Destination"
                                                                        classes={
                                                                              4
                                                                        }
                                                                        loading={
                                                                              loadingData
                                                                        }
                                                                        selected={
                                                                              destination
                                                                        }
                                                                        setData={
                                                                              setDestination
                                                                        }
                                                                        required={
                                                                              true
                                                                        }
                                                                        options={
                                                                              destionations
                                                                        }
                                                                  />
                                                            )}
                                                            <Input
                                                                  title="Location"
                                                                  classes={4}
                                                                  value={
                                                                        location
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setLocation
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  title="Duration"
                                                                  options={
                                                                        durations
                                                                  }
                                                                  classes={4}
                                                                  required={
                                                                        true
                                                                  }
                                                                  selected={
                                                                        duration
                                                                  }
                                                                  setData={
                                                                        setDuration
                                                                  }
                                                            />
                                                            <Input
                                                                  title="Group Size"
                                                                  classes={4}
                                                                  value={
                                                                        groupSize
                                                                  }
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setGroupSize
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Hotel Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  selected={
                                                                        hotelCategory
                                                                  }
                                                                  setData={
                                                                        setHotelCategory
                                                                  }
                                                                  options={
                                                                        hotelCategories
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Vehicle"
                                                                  classes={4}
                                                                  loading={
                                                                        loadingData
                                                                  }
                                                                  setData={
                                                                        setVehicle
                                                                  }
                                                                  selected={
                                                                        vehicle
                                                                  }
                                                                  options={
                                                                        vehicles
                                                                  }
                                                            />
                                                            <Input
                                                                  title="Price"
                                                                  classes={4}
                                                                  required={
                                                                        true
                                                                  }
                                                                  value={price}
                                                                  handleChange={
                                                                        setPrice
                                                                  }
                                                            />
                                                            <Input
                                                                  title="Offer Price"
                                                                  classes={4}
                                                                  handleChange={
                                                                        setOfferPrice
                                                                  }
                                                            />
                                                            <Input
                                                                  title="Price Type"
                                                                  classes={4}
                                                                  required={
                                                                        true
                                                                  }
                                                                  value={
                                                                        priceType
                                                                  }
                                                                  handleChange={
                                                                        setPriceType
                                                                  }
                                                            />
                                                            <FormStatusSelect
                                                                  title="Status"
                                                                  classes={4}
                                                                  selected={
                                                                        status
                                                                  }
                                                                  setStatus={
                                                                        setStatus
                                                                  }
                                                            />
                                                            <FormDescription
                                                                  title="Description"
                                                                  setDescription={
                                                                        setDescription
                                                                  }
                                                                  description={
                                                                        description
                                                                  }
                                                            />
                                                      </Row>
                                                ) : (
                                                      <>
                                                            <div className="my-5 text-center">
                                                                  <Spinner />
                                                            </div>
                                                      </>
                                                )}
                                          </Card.Body>
                                    </Card>
                                    {!loadingData && (
                                          <>
                                                <Card className="mt-4">
                                                      <Card.Body>
                                                            <Row className="g-3">
                                                                  <FormImage
                                                                        title="Featured Image"
                                                                        setImage={
                                                                              setImage
                                                                        }
                                                                        setImageUrl={
                                                                              setImageUrl
                                                                        }
                                                                        imageUrl={
                                                                              imageUrl
                                                                        }
                                                                  />
                                                                  <FormImage
                                                                        title="Banner Image"
                                                                        setImage={
                                                                              setBannerImage
                                                                        }
                                                                        setImageUrl={
                                                                              setBannerImageUrl
                                                                        }
                                                                        imageUrl={
                                                                              bannerImageUrl
                                                                        }
                                                                  />
                                                                  <MultipleImage
                                                                        title="Multiple Images"
                                                                        grid={4}
                                                                        classes={
                                                                              12
                                                                        }
                                                                        images={
                                                                              images
                                                                        }
                                                                        websiteImages={
                                                                              productImages
                                                                        }
                                                                        type="packages"
                                                                        setProductImages={
                                                                              setProductImages
                                                                        }
                                                                        setImages={
                                                                              setImages
                                                                        }
                                                                        slug={
                                                                              packageId
                                                                        }
                                                                        setImageFiles={
                                                                              setImageFiles
                                                                        }
                                                                  />
                                                            </Row>
                                                      </Card.Body>
                                                </Card>
                                                <FormSeo
                                                      setSeoDescription={
                                                            setSeoDescription
                                                      }
                                                      setSeoTitle={setSeoTitle}
                                                      setSeoKeyword={
                                                            setSeoKeyword
                                                      }
                                                      title={seoTitle}
                                                      keyword={seoKeyword}
                                                      description={
                                                            seoDescription
                                                      }
                                                />

                                                <ExtraDetail
                                                      itinearies={itinearies}
                                                      setItinearies={
                                                            setItinearies
                                                      }
                                                      type="packages"
                                                      slug={packageId}
                                                />

                                                <Card className="mt-4 mb-5">
                                                      <Card.Body>
                                                            <Row>
                                                                  <Col lg={12}>
                                                                        <FormButton
                                                                              title="Submit"
                                                                              loading={
                                                                                    loading
                                                                              }
                                                                        />
                                                                  </Col>
                                                            </Row>
                                                      </Card.Body>
                                                </Card>
                                          </>
                                    )}
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default PackageEdit;
