import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const DestinationCountryShow = () => {
      const { countryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [country, setCountry] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/destination_countries/${countryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCountry(response.data.country);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Destination Country Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/destination_countries"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        country.title
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="By"
                                                                  value={
                                                                        country.user_name
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        country.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Featured"
                                                                  value={
                                                                        country.featured ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        country.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DestinationCountryShow;
