import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import CardHeader from '../../components/common/CardHeader';
import { ItemShow } from '../../components/form';
import LinkButton from '../../components/common/LinkButton';

const ComplainShow = () => {
      const { complainId } = useParams();
      const [complain, setComplain] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/complains/${complainId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setComplain(response.data.complain);
                        }
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Complain Details">
                                          <LinkButton
                                                link="/dashboard/complains"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Product Name"
                                                                  value={
                                                                        complain.product_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Serial No."
                                                                  value={
                                                                        complain.product_serial
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Purchase Date"
                                                                  value={
                                                                        complain.date_of_purchase
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        complain.category
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Complain"
                                                                  value={
                                                                        complain.complain_type
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Remarks"
                                                                  value={
                                                                        complain.remarks
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        complain.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email"
                                                                  value={
                                                                        complain.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Mobile"
                                                                  value={
                                                                        complain.phone_number
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Address"
                                                                  value={
                                                                        complain.address +
                                                                        ' - ' +
                                                                        complain.landmark +
                                                                        ', ' +
                                                                        complain.street +
                                                                        ', ' +
                                                                        complain.city +
                                                                        ', ' +
                                                                        complain.state
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Apply On"
                                                                  value={
                                                                        complain.date
                                                                  }
                                                            />
                                                      </ListGroup>

                                                      <div className="d-flex p-3 gap-3">
                                                            {complain.warrenty && (
                                                                  <div>
                                                                        <img
                                                                              style={{
                                                                                    width: '120px'
                                                                              }}
                                                                              src={
                                                                                    complain.warrenty
                                                                              }
                                                                              alt=""
                                                                              className="rounded"
                                                                        />
                                                                        <div>
                                                                              Warrenty
                                                                        </div>
                                                                  </div>
                                                            )}
                                                            {complain.bill_card && (
                                                                  <div>
                                                                        <img
                                                                              style={{
                                                                                    width: '120px'
                                                                              }}
                                                                              src={
                                                                                    complain.bill_card
                                                                              }
                                                                              alt=""
                                                                              className="rounded"
                                                                        />
                                                                        <div>
                                                                              Billing
                                                                              Card
                                                                        </div>
                                                                  </div>
                                                            )}
                                                      </div>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ComplainShow;
