import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import BlogListBox from '../../components/backend/BlogListBox';
import { ItemShow } from '../../components/form';

const BlogCategoryShow = () => {
      const { blogCategoryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [category, setCategory] = useState([]);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const [blogs, setBlogs] = useState([]);
      const [totalBlogs, setTotalBlogs] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blog_categories/${blogCategoryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategory(response.data.blogCategory);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      const loadCategory = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blog_categories/${blogCategoryId}/edit?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                              setTotalBlogs(response.data.totalBlogs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blog_categories/${blogCategoryId}/edit?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                              setTotalBlogs(response.data.totalBlogs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      useEffect(() => {
            loadCategory();
      }, [limit, search]);

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Category Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/blogs/categories/list"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        category.title
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        category.date
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="User Name"
                                                                  value={
                                                                        category.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Title"
                                                                  value={
                                                                        category.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Keyword"
                                                                  value={
                                                                        category.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Description"
                                                                  value={
                                                                        category.seo_description
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        category.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={5}>
                                                      <div className="d-flex gap-3">
                                                            {category.image && (
                                                                  <div
                                                                        style={{
                                                                              width: '220px'
                                                                        }}
                                                                  >
                                                                        <img
                                                                              src={`${category.image}`}
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="text-muted text-center">
                                                                              Featured
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                            )}
                                                            {category.seo_image && (
                                                                  <div
                                                                        style={{
                                                                              width: '220px'
                                                                        }}
                                                                  >
                                                                        <img
                                                                              src={`${category.seo_image}`}
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="text-muted text-center">
                                                                              SEO
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                            )}
                                                      </div>
                                                      {category.description && (
                                                            <div className="mt-5 border-top py-2">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: category.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      )}
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>

                              <Card className="mt-4">
                                    <CardHeader title="Blog List" />

                                    <BlogListBox
                                          loading={loading}
                                          blogs={blogs}
                                          totalBlogs={totalBlogs}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          fetchBlogs={loadCategory}
                                          setLoading={setLoading}
                                          setBlogs={setBlogs}
                                          setSearch={setSearch}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BlogCategoryShow;
