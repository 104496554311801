import React, { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { ImageThumbnailSlider, ImageViewSlider } from './styles';

const ImageSlider = ({ images, image }) => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      return (
            <>
                  <ImageViewSlider
                        style={{
                              '--swiper-navigation-color': '#fff',
                              '--swiper-pagination-color': '#fff'
                        }}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                  >
                        {image && (
                              <SwiperSlide>
                                    <img src={image} />
                              </SwiperSlide>
                        )}
                        {images &&
                              images?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                          <img src={item.image} />
                                    </SwiperSlide>
                              ))}
                  </ImageViewSlider>
                  <ImageThumbnailSlider
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                  >
                        {image && (
                              <SwiperSlide>
                                    <img src={image} />
                              </SwiperSlide>
                        )}
                        {images &&
                              images?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                          <img src={item.image} />
                                    </SwiperSlide>
                              ))}
                  </ImageThumbnailSlider>
            </>
      );
};

export default ImageSlider;
