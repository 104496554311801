import axios from 'axios';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FormDescription } from '../../form';

const ItineraryCreate = ({ itinearies, setItinearies, type, slug }) => {
      const handleChange = (index, e, name) => {
            const values = [...itinearies];
            if (name === 'description') {
                  values[index][name] = e;
            } else {
                  values[index][name] = e.target.value;
            }
            setItinearies(values);
      };

      const handleAddFields = () => {
            setItinearies([...itinearies, { title: '', description: '' }]);
      };

      const handleRemoveFields = async (index) => {
            const values = [...itinearies];
            if (type) {
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/${type}/itinearies/${slug}/remove`,
                              {
                                    image: index
                              },
                              {
                                    headers: {
                                          Authorization: `Bearer ${JSON.parse(
                                                localStorage.getItem('token')
                                          )}`,
                                          'Content-Type': 'multipart/form-data'
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    toast.success(response.data.message);

                                    values.splice(index, 1);
                                    setItinearies(values);
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            }
      };

      return (
            <>
                  <div>
                        {itinearies.map((field, index) => (
                              <Row key={index} className="g-3">
                                    <Col lg={12}>
                                          <Form.Label>Title</Form.Label>
                                          <Form.Control
                                                type="text"
                                                name="title"
                                                value={field.title}
                                                onChange={(e) =>
                                                      handleChange(index, e, "title")
                                                }
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          <FormDescription
                                                as="textarea"
                                                title="Description"
                                                name="description"
                                                description={field.description}
                                                rows={4}
                                                setDescription={(e) =>
                                                      handleChange(
                                                            index,
                                                            e,
                                                            'description'
                                                      )
                                                }
                                          />
                                    </Col>
                                    <Col lg={4}>
                                          <button
                                                type="button"
                                                onClick={() =>
                                                      handleRemoveFields(index)
                                                }
                                                className="btn btn-danger"
                                          >
                                                Remove
                                          </button>
                                    </Col>
                              </Row>
                        ))}
                        <button
                              type="button"
                              className="btn btn-success mt-4"
                              onClick={handleAddFields}
                        >
                              Add Field
                        </button>
                  </div>
            </>
      );
};

export default ItineraryCreate;
