import React, { useEffect, useState } from 'react';
import ProfileLayout from '../ProfileLayout';
import ProfileTitle from '../../components/profile/ProfileTitle';
import { Form } from 'react-bootstrap';
import { FormButton, FormImage, FormInput } from '../../components/form';
import axios from 'axios';
import toast from 'react-hot-toast';

const LogoSetting = () => {
      const [loading, setLoading] = useState(false);
      const [logo, setLogo] = useState('');
      const [logoLink, setLogoLink] = useState('');
      const [whiteLogo, setWhiteLogo] = useState('');
      const [whiteLogoLink, setWhiteLogoLink] = useState('');
      const [colorLogo, setColorLogo] = useState('');
      const [colorLogoLink, setColorLogoLink] = useState('');
      const [playList, setPlayList] = useState('');
      const [videoLink, setVideoLink] = useState('');
      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/logo_settings`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setLogoLink(response.data.settings.logo);
                              setWhiteLogoLink(
                                    response.data.settings.white_logo
                              );
                              setVideoLink(response.data.settings.video_link);
                              setColorLogoLink(
                                    response.data.settings.color_gif
                              );
                              setPlayList(
                                    response.data.settings.youtube_playlist
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  logo: logo,
                  white_logo: whiteLogo,
                  video_link: videoLink,
                  color_logo: colorLogo,
                  youtube_playlist: playList
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/logo_settings`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result == 'error') {
                              if (response.data.message.logo) {
                                    toast.error(response.data.message.logo);
                              }
                              if (response.data.message.white_logo) {
                                    toast.error(
                                          response.data.message.white_logo
                                    );
                              }
                              if (response.data.message.video_link) {
                                    toast.error(
                                          response.data.message.video_link
                                    );
                              }
                              if (response.data.message.youtube_playlist) {
                                    toast.error(
                                          response.data.message.youtube_playlist
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <ProfileLayout>
                        <ProfileTitle title="Logo Setting" />
                        <Form className="row g-3" onSubmit={handleFormSubmit}>
                              <FormImage
                                    title="Color Logo"
                                    setImageUrl={setLogoLink}
                                    setImage={setLogo}
                                    imageUrl={logoLink}
                                    grid={1}
                              />
                              <FormImage
                                    title="White Logo"
                                    setImageUrl={setWhiteLogoLink}
                                    setImage={setWhiteLogo}
                                    imageUrl={whiteLogoLink}
                                    grid={1}
                                    color="#333"
                              />
                              <FormImage
                                    title="Color Gif"
                                    setImageUrl={setColorLogoLink}
                                    setImage={setColorLogo}
                                    imageUrl={colorLogoLink}
                                    grid={1}
                                    color="#333"
                              />
                              <FormInput
                                    title="Youtube Video Link"
                                    value={videoLink}
                                    handleChange={setVideoLink}
                              />
                              <FormInput
                                    title="Youtube Playlist Code"
                                    value={playList}
                                    handleChange={setPlayList}
                              />
                              <div className="col-md-12">
                                    <FormButton
                                          loading={loading}
                                          title="Update"
                                    />
                              </div>
                        </Form>
                  </ProfileLayout>
            </>
      );
};

export default LogoSetting;
