import React, { useRef } from 'react';
import {
      MetisMenuIcon,
      MetisMenuLink,
      MetisMenuList,
      MetisMenuTitle
} from './styles';
import { matchPath, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const MenuItem = ({ link, title, icon, children, links }) => {
      const location = useLocation();
      const activeRef = useRef(null);

      let isMatchingPath = false;
      if (links) {
            isMatchingPath = links.some(
                  (link) =>
                        !!matchPath(
                              {
                                    path: link
                              },
                              location.pathname
                        )
            );
      }

      useEffect(() => {
            if (activeRef.current) {
                  activeRef.current.scrollIntoView({ behavior: 'smooth' });
            }
      }, [location]);

      return (
            <>
                  <MetisMenuList>
                        <MetisMenuLink
                              className={`${children && 'has-arrow'} ${
                                    isMatchingPath && 'active'
                              }`}
                              to={link ? link : '#'}
                              ref={
                                    !children && isMatchingPath
                                          ? activeRef
                                          : null
                              }
                              aria-expanded="false"
                        >
                              <MetisMenuIcon>{icon}</MetisMenuIcon>
                              <MetisMenuTitle>
                                    <span>{title}</span>
                              </MetisMenuTitle>
                        </MetisMenuLink>
                        {children && <ul>{children}</ul>}
                  </MetisMenuList>
            </>
      );
};

export default MenuItem;
