import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { ItemShow } from '../../components/form';

const PageShow = () => {
      const { pageId } = useParams();

      const [page, setPage] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${pageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPage(response.data.page);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Page Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/pages"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        page.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Summary"
                                                                  value={
                                                                        page.summary
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Title"
                                                                  value={
                                                                        page.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Keyword"
                                                                  value={
                                                                        page.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="SEO Description"
                                                                  value={
                                                                        page.seo_description
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        page.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={5}>
                                                      <div className="d-flex gap-3">
                                                            {page.image && (
                                                                  <div
                                                                        style={{
                                                                              width: '220px'
                                                                        }}
                                                                  >
                                                                        <img
                                                                              src={`${page.image}`}
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="text-muted text-center">
                                                                              Featured
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                            )}
                                                            {page.seo_image && (
                                                                  <div
                                                                        style={{
                                                                              width: '220px'
                                                                        }}
                                                                  >
                                                                        <img
                                                                              src={`${page.seo_image}`}
                                                                              className="w-100 h-100"
                                                                        />
                                                                        <div className="text-muted text-center">
                                                                              SEO
                                                                              Image
                                                                        </div>
                                                                  </div>
                                                            )}
                                                      </div>
                                                      {page.description && (
                                                            <div className="mt-5 border-top py-2">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: page.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      )}
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PageShow;
