import React, { useEffect, useState } from 'react';
import ProfileLayout from '../ProfileLayout';
import ProfileTitle from '../../components/profile/ProfileTitle';
import { FormButton, FormImage, FormInput } from '../../components/form';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';

const Setting = () => {
      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);

      const [address, setAddress] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/general_settings`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setAddress(response.data.settings.address);
                        setEmail(response.data.settings.email);
                        setPhoneNumber(response.data.settings.phone_number);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  address: address,
                  email_address: email,
                  phone_number: phoneNumber
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/general_settings`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result == 'error') {
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.email_address) {
                                    toast.error(
                                          response.data.message.email_address
                                    );
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <ProfileLayout>
                        <ProfileTitle title="General Setting" />
                        <Form className="row gap-3" onSubmit={handleFormSubmit}>
                              <FormInput
                                    title="Address"
                                    value={address}
                                    required={true}
                                    handleChange={setAddress}
                              />
                              <FormInput
                                    title="Email Address"
                                    required={true}
                                    value={email}
                                    handleChange={setEmail}
                              />
                              <FormInput
                                    title="Phone Number"
                                    value={phoneNumber}
                                    required={true}
                                    handleChange={setPhoneNumber}
                              />
                              <div className="col-md-12">
                                    <FormButton
                                          loading={loading}
                                          title="Update"
                                    />
                              </div>
                        </Form>
                  </ProfileLayout>
            </>
      );
};

export default Setting;
