import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSeo,
      FormStatusSelect
} from '../../components/form';

const BlogCategoryEdit = () => {
      const { blogCategoryId } = useParams();
      const navigate = useNavigate();

      const [category, setCategory] = useState([]);
      const [title, setTitle] = useState('');
      const [slug, setSlug] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [description, setDescription] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoImage, setSeoImage] = useState('');
      const [seoImageUrl, setSeoImageUrl] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blog_categories/${blogCategoryId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            ).then((response) => {
                  if (response.data.result === 'success') {
                        setCategory(response.data.blogCategory);
                        setSlug(response.data.blogCategory.slug);
                        setTitle(response.data.blogCategory.title);
                        setDescription(response.data.blogCategory.description);
                        setStatus(response.data.blogCategory.status);
                        setImageUrl(response.data.blogCategory.image);
                        setSeoImageUrl(response.data.blogCategory.seo_image);
                        setSeoTitle(response.data.blogCategory.seo_title);
                        setSeoKeyword(response.data.blogCategory.seo_keyword);
                        setSeoDescription(
                              response.data.blogCategory.seo_description
                        );
                  }
            });
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  status: status,
                  description: description,
                  image: image,
                  seo_title: seoTitle,
                  seo_image: seoImage,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription
            };

            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(
                              `/dashboard/blogs/categories/${updatedSlug}/edit`
                        );
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blog_categories/${blogCategoryId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_image) {
                                    toast.error(
                                          response.data.message.seo_image
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col>
                                    <Card>
                                          <CardHeader title="Create Category">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/blogs/categories/list"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                            value={title}
                                                      />
                                                      <FormInput
                                                            title="Slug"
                                                            required={true}
                                                            value={slug}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormDescription
                                                            title="Description"
                                                            description={
                                                                  description
                                                            }
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          seoImageUrl={seoImageUrl}
                                          setSeoImage={setSeoImage}
                                          setSeoImageUrl={setSeoImageUrl}
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          title={seoTitle}
                                          keyword={seoKeyword}
                                          description={seoDescription}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default BlogCategoryEdit;
