import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import { ItemShow } from '../../components/form';
import LinkButton from '../../components/common/LinkButton';

const PluginShow = () => {
      const { pluginId } = useParams();
      const [plugin, setPlugin] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadPlugin = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/plugins/${pluginId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPlugin(response.data.plugin);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadPlugin();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Plugin Detail">
                                          <LinkButton
                                                link="/dashboard/plugins"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        plugin.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Type"
                                                                  value={
                                                                        plugin.type
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Code"
                                                                  value={
                                                                        plugin.code
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        plugin.status ===
                                                                        1 ? (
                                                                              <label className="text-success">
                                                                                    Active
                                                                              </label>
                                                                        ) : (
                                                                              <label className="text-danger">
                                                                                    Disabled
                                                                              </label>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created Date"
                                                                  value={
                                                                        plugin.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Created By"
                                                                  value={
                                                                        plugin.user_name
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PluginShow;
