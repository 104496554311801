import React from 'react';

const PackageEnquiryIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">inventory</span>
            </>
      );
};

export default PackageEnquiryIcon;
