import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, Navigate } from 'react-router-dom';
import './style.css';
import {
      DropdownContent,
      DropdownHeader,
      DropdownMenu,
      DropdownToggle,
      HeaderFunction,
      HeaderLeftBar,
      HeaderNotification,
      HeaderProfile,
      HeaderProfileContent,
      HeaderProfileDetail,
      MainContent,
      MainHeader,
      ProfileLink
} from './styles';
import { NotificationIcon } from '../../components/icons';

const Header = ({ children }) => {
      const login = localStorage.getItem('isLoggedIn');
      const token = JSON.parse(localStorage.getItem('token'));

      function onLogoutHandler() {
            localStorage.clear();
      }

      useEffect(() => {
            if (token == null) {
                  onLogoutHandler();
            }
      });

      if (login === null) {
            return <Navigate to="/" />;
      }

      return (
            <>
                  <MainContent>
                        <MainHeader>
                              <HeaderLeftBar>
                                    <Link
                                          to="https://aircasa.in/"
                                          target="_blank"
                                    >
                                          Visit Website
                                    </Link>
                              </HeaderLeftBar>
                              <HeaderFunction>
                                    <HeaderNotification>
                                          <Dropdown>
                                                <DropdownToggle>
                                                      <NotificationIcon />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                      <DropdownHeader>
                                                            <h4 className="text-light">
                                                                  Notifications
                                                            </h4>
                                                      </DropdownHeader>
                                                      <DropdownContent>
                                                            <ul>
                                                                  {/* <li>
                                                                        <a>
                                                                              1.
                                                                              An
                                                                              Order
                                                                              has
                                                                              been
                                                                              placed
                                                                        </a>
                                                                  </li> */}
                                                            </ul>
                                                      </DropdownContent>
                                                </DropdownMenu>
                                          </Dropdown>
                                    </HeaderNotification>
                                    <HeaderProfile>
                                          <img
                                                src="/images/member1.jpg"
                                                alt=""
                                                className="img-fluid"
                                          />
                                          <HeaderProfileContent>
                                                <p>
                                                      Welcome <br />
                                                      Admin
                                                </p>
                                                <HeaderProfileDetail>
                                                      <ProfileLink to="/dashboard/profile">
                                                            My Profile
                                                            <span class="material-icons">
                                                                  person
                                                            </span>
                                                      </ProfileLink>
                                                      {/* 
                                                      <ProfileLink to="#">
                                                            Company Info
                                                            <span class="material-icons">
                                                                  group
                                                            </span>
                                                      </ProfileLink>
                                                       */}
                                                      <ProfileLink to="/dashboard/settings">
                                                            Settings
                                                            <span class="material-icons">
                                                                  settings
                                                            </span>
                                                      </ProfileLink>
                                                      <ProfileLink
                                                            role="button"
                                                            onClick={
                                                                  onLogoutHandler
                                                            }
                                                      >
                                                            Log out
                                                            <span class="material-icons">
                                                                  logout
                                                            </span>
                                                      </ProfileLink>
                                                </HeaderProfileDetail>
                                          </HeaderProfileContent>
                                    </HeaderProfile>
                              </HeaderFunction>
                        </MainHeader>
                        {children}
                  </MainContent>
            </>
      );
};

export default Header;
