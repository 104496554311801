import React from 'react';

const PlanTripIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        carry_on_bag
                  </span>
            </>
      );
};

export default PlanTripIcon;
