import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import DashboardItem from './DashboardItem';
import axios from 'axios';
import {
      ContactIcon,
      CustomizeTripIcon,
      DestinationIcon,
      ExperienceIcon,
      PackageEnquiryIcon,
      PackageIcon,
      PlanTripIcon,
      TestimonialIcon
} from '../../components/icons';

const Dashboard = () => {
      const [orders, setOrders] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalPackages, setTotalPackages] = useState(0);
      const [totalExperiences, setTotalExperiences] = useState(0);
      const [totalPackageEnquiries, setTotalPackageEnquiries] = useState(0);
      const [totalTripPlans, setTotalTripPlans] = useState(0);
      const [totalCostomizes, setTotalCustomizes] = useState(0);
      const [totalTestimonials, setTotalTestimonials] = useState(0);
      const [totalContactRequests, setTotalContactRequests] = useState(0);
      const [totalDestinations, setTotalDestinations] = useState(0);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/dashboard`, {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setOrders(response.data.orders);
                              setTotalPackages(response.data.totalPackages);
                              setTotalTripPlans(response.data.totalTripPlans);
                              setTotalExperiences(
                                    response.data.totalExperiences
                              );
                              setTotalPackageEnquiries(
                                    response.data.totalPackageEnquiries
                              );
                              setTotalCustomizes(response.data.totalCostomizes);
                              setTotalDestinations(
                                    response.data.totalDestinations
                              );
                              setTotalContactRequests(
                                    response.data.totalContactRequests
                              );
                              setTotalTestimonials(
                                    response.data.totalTestimonials
                              );
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row className="g-3">
                        <DashboardItem
                              title="Total Packages"
                              total={totalPackages}
                              link="/dashboard/packages"
                              icon={<PackageIcon />}
                        />

                        <DashboardItem
                              title="Total Destination"
                              total={totalDestinations}
                              link="/dashboard/destinations"
                              icon={<DestinationIcon />}
                        />
                        <DashboardItem
                              title="Total Experience"
                              total={totalExperiences}
                              link="/dashboard/experiences"
                              icon={<ExperienceIcon />}
                        />
                        <DashboardItem
                              title="Total Testimonials"
                              total={totalTestimonials}
                              link="/dashboard/testimonials"
                              icon={<TestimonialIcon />}
                        />
                        <DashboardItem
                              title="Total Package Enquiry"
                              total={totalPackageEnquiries}
                              link="/dashboard/package_enquiries"
                              icon={<PackageEnquiryIcon />}
                        />
                        <DashboardItem
                              title="Customize Trip Request"
                              total={totalCostomizes}
                              link="/dashboard/customize_trip_requests"
                              icon={<CustomizeTripIcon />}
                        />
                        <DashboardItem
                              title="Total Plan Trip Request"
                              total={totalTripPlans}
                              link="/dashboard/plan_trip_requests"
                              icon={<PlanTripIcon />}
                        />
                        <DashboardItem
                              title="Total Contact Request"
                              total={totalContactRequests}
                              link="/dashboard/contact/requests"
                              icon={<ContactIcon />}
                        />
                  </Row>
            </>
      );
};

export default Dashboard;
