import React from 'react';
import CheckPermission from '../../roles/CheckPermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import DashboardIcon from '../../dashboard/DashboardIcon';

const DashboardManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckPermission
                        userPermissions={userPermissions}
                        permission="Dashboard"
                  >
                        <MenuSeparator>Dashboard</MenuSeparator>
                        <MenuItem
                              link="/dashboard"
                              title="Dashboard"
                              icon={<DashboardIcon />}
                              links={[
                                    '/dashboard'
                              ]}
                        />
                  </CheckPermission>
            </>
      );
};

export default DashboardManage;
