import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MetisMenuLink = styled(Link)`
      display: flex;
      align-items: center;
      padding: 4px 7px 4px 28px;
      font-weight: 400;
      color: ${(props) => props.theme.linkColor};
      text-decoration: none;

      &.active {
            background-color: ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
      }

      &:hover,
      &:active {
            background-color: ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
      }
`;

export const MetisMenuTitle = styled.div`
      width: 100%;
      span {
            text-decoration: none !important;
            font-size: 14px;
      }
`;

export const MetisMenuIcon = styled.div`
      i,
      span {
            width: 30px;
            font-size: 20px;
      }
`;

export const MetisMenuList = styled.li`
      &.mm-active > a.active {
            background: #e86b19;
            color: white;
      }
`;
