import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import { ProductIcon } from '../../dashboard';
import {
      DestinationIcon,
      ExperienceIcon,
      PackageEnquiryIcon,
      PackageIcon,
      PlanTripIcon
} from '../../icons';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'package-list',
                              'package-create',
                              'package-edit',
                              'package-show',
                              'hotel-category-list',
                              'hotel-category-create',
                              'hotel-category-edit',
                              'hotel-category-show',
                              'package-enquiry-list',
                              'package-enquiry-create',
                              'package-enquiry-edit',
                              'package-enquiry-show',
                              'vehicle-list',
                              'vehicle-create',
                              'vehicle-edit',
                              'vehicle-show',
                              'destination-category-list',
                              'destination-category-create',
                              'destination-category-edit',
                              'destination-category-delete',
                              'destination-list',
                              'destination-create',
                              'destination-edit',
                              'destination-delete',
                              'customize-trip-request-list',
                              'customize-trip-request-delete',
                              'departing-city-list',
                              'departing-city-create',
                              'departing-city-edit',
                              'departing-city-delete',
                              'destination-country-list',
                              'destination-country-create',
                              'destination-country-edit',
                              'destination-country-delete'
                        ]}
                  >
                        <MenuSeparator>Tour Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'package-list',
                                    'package-create',
                                    'package-edit',
                                    'package-show',
                                    'vehicle-list',
                                    'vehicle-create',
                                    'vehicle-edit',
                                    'vehicle-show',
                                    'package-enquiry-list',
                                    'package-enquiry-create',
                                    'package-enquiry-edit',
                                    'package-enquiry-show'
                              ]}
                        >
                              <MenuItem
                                    title="Package Manage"
                                    icon={<PackageIcon />}
                                    links={[
                                          '/dashboard/products',
                                          '/dashboard/products/create',
                                          '/dashboard/products/:productId/edit',
                                          '/dashboard/products/:productId/show',
                                          '/dashboard/vehicles',
                                          '/dashboard/vehicles/create',
                                          '/dashboard/vehicles/:vehicleId/edit',
                                          '/dashboard/vehicles/:vehicleId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'vehicle-list',
                                                'vehicle-create',
                                                'vehicle-edit',
                                                'vehicle-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Vehicle List"
                                                link="/dashboard/vehicles"
                                                links={[
                                                      '/dashboard/vehicles',
                                                      '/dashboard/vehicles/create',
                                                      '/dashboard/vehicles/:vehicleId/edit',
                                                      '/dashboard/vehicles/:vehicleId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'package-list',
                                                'package-create',
                                                'package-edit',
                                                'package-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Package List"
                                                link="/dashboard/packages"
                                                links={[
                                                      '/dashboard/packages',
                                                      '/dashboard/packages/create',
                                                      '/dashboard/packages/:packageId/edit',
                                                      '/dashboard/packages/:packageId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'hotel-category-list',
                                                'hotel-category-create',
                                                'hotel-category-edit',
                                                'hotel-category-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Hotel Categories"
                                                link="/dashboard/hotel_categories"
                                                links={[
                                                      '/dashboard/hotel_categories',
                                                      '/dashboard/hotel_categories/create',
                                                      '/dashboard/hotel_categories/:typeId/edit',
                                                      '/dashboard/hotel_categories/:typeId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'experience-list',
                                    'experience-create',
                                    'experience-edit',
                                    'experience-show',
                                    'experience-collection-list',
                                    'experience-collection-create',
                                    'experience-collection-edit',
                                    'experience-collection-show',
                                    'experience-package-list',
                                    'experience-package-create',
                                    'experience-package-edit',
                                    'experience-package-show'
                              ]}
                        >
                              <MenuItem
                                    title="Experience Manage"
                                    icon={<ExperienceIcon />}
                                    links={[
                                          '/dashboard/experiences',
                                          '/dashboard/experiences/create',
                                          '/dashboard/experiences/:experienceId/edit',
                                          '/dashboard/experiences/:experienceId/show',
                                          '/dashboard/experience_collections',
                                          '/dashboard/experience_collections/create',
                                          '/dashboard/experience_collections/:collectionId/edit',
                                          '/dashboard/experience_collections/:collectionId/show',
                                          '/dashboard/experience_packages',
                                          '/dashboard/experience_packages/create',
                                          '/dashboard/experience_packages/:packageId/edit',
                                          '/dashboard/experience_packages/:packageId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'experience-list',
                                                'experience-create',
                                                'experience-edit',
                                                'experience-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Experience List"
                                                link="/dashboard/experiences"
                                                links={[
                                                      '/dashboard/experiences',
                                                      '/dashboard/experiences/create',
                                                      '/dashboard/experiences/:experienceId/edit',
                                                      '/dashboard/experiences/:experienceId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'experience-collection-list',
                                                'experience-collection-create',
                                                'experience-collection-edit',
                                                'experience-collection-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Collection List"
                                                link="/dashboard/experience_collections"
                                                links={[
                                                      '/dashboard/experience_collections',
                                                      '/dashboard/experience_collections/create',
                                                      '/dashboard/experience_collections/:collectionId/edit',
                                                      '/dashboard/experience_collections/:collectionId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'experience-package-list',
                                                'experience-package-create',
                                                'experience-package-edit',
                                                'experience-package-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Package List"
                                                link="/dashboard/experience_packages"
                                                links={[
                                                      '/dashboard/experience_packages',
                                                      '/dashboard/experience_packages/create',
                                                      '/dashboard/experience_packages/:packageId/edit',
                                                      '/dashboard/experience_packages/:packageId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'destination-category-list',
                                    'destination-category-create',
                                    'destination-category-edit',
                                    'destination-category-delete',
                                    'destination-list',
                                    'destination-create',
                                    'destination-edit',
                                    'destination-delete',
                                    'departing-city-list',
                                    'departing-city-create',
                                    'departing-city-edit',
                                    'departing-city-delete',
                                    'destination-country-list',
                                    'destination-country-create',
                                    'destination-country-edit',
                                    'destination-country-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Destination Manage"
                                    icon={<DestinationIcon />}
                                    links={[
                                          '/dashboard/destinations',
                                          '/dashboard/destinations/create',
                                          '/dashboard/destinations/:destinationId/edit',
                                          '/dashboard/destinations/:destinationId/show',
                                          '/dashboard/destination_categories',
                                          '/dashboard/destination_categories/create',
                                          '/dashboard/destination_categories/:categoryId/edit',
                                          '/dashboard/destination_categories/:categoryId/show',
                                          '/dashboard/destination_countries',
                                          '/dashboard/destination_countries/create',
                                          '/dashboard/destination_countries/:countryId/edit',
                                          '/dashboard/destination_countries/:countryId/show',
                                          '/dashboard/departing_cities',
                                          '/dashboard/departing_cities/create',
                                          '/dashboard/departing_cities/:cityId/edit',
                                          '/dashboard/departing_cities/:cityId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'destination-category-list',
                                                'destination-category-create',
                                                'destination-category-edit',
                                                'destination-category-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/destination_categories"
                                                links={[
                                                      '/dashboard/destination_categories',
                                                      '/dashboard/destination_categories/create',
                                                      '/dashboard/destination_categories/:categoryId/edit',
                                                      '/dashboard/destination_categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'destination-list',
                                                'destination-create',
                                                'destination-edit',
                                                'destination-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Destination List"
                                                link="/dashboard/destinations"
                                                links={[
                                                      '/dashboard/destinations',
                                                      '/dashboard/destinations/create',
                                                      '/dashboard/destinations/:destinationId/edit',
                                                      '/dashboard/destinations/:destinationId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'departing-city-list',
                                                'departing-city-create',
                                                'departing-city-edit',
                                                'departing-city-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Departing City List"
                                                link="/dashboard/departing_cities"
                                                links={[
                                                      '/dashboard/departing_cities',
                                                      '/dashboard/departing_cities/create',
                                                      '/dashboard/departing_cities/:cityId/edit',
                                                      '/dashboard/departing_cities/:cityId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'destination-country-list',
                                                'destination-country-create',
                                                'destination-country-edit',
                                                'destination-country-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Destination Country List"
                                                link="/dashboard/destination_countries"
                                                links={[
                                                      '/dashboard/destination_countries',
                                                      '/dashboard/destination_countries/create',
                                                      '/dashboard/destination_countries/:countryId/edit',
                                                      '/dashboard/destination_countries/:countryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'package-enquiry-list',
                                    'package-enquiry-create',
                                    'package-enquiry-edit',
                                    'package-enquiry-show',
                                    'customize-trip-request-list',
                                    'customize-trip-request-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Enquiry Manage"
                                    icon={<PlanTripIcon />}
                                    links={[
                                          '/dashboard/package_enquiries',
                                          '/dashboard/package_enquiries/create',
                                          '/dashboard/package_enquiries/:enquiryId/edit',
                                          '/dashboard/package_enquiries/:enquiryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'package-enquiry-list',
                                                'package-enquiry-create',
                                                'package-enquiry-edit',
                                                'package-enquiry-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Package Enquiry"
                                                link="/dashboard/package_enquiries"
                                                links={[
                                                      '/dashboard/package_enquiries',
                                                      '/dashboard/package_enquiries/create',
                                                      '/dashboard/package_enquiries/:enquiryId/edit',
                                                      '/dashboard/package_enquiries/:enquiryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'customize-trip-request-list',
                                                'customize-trip-request-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Customize Trip Request"
                                                link="/dashboard/customize_trip_requests"
                                                links={[
                                                      '/dashboard/customize_trip_requests',
                                                      '/dashboard/customize_trip_requests/:enquiryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
