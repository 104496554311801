import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import Select from 'react-select';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormInput,
      FormSelect,
      FormStatusSelect
} from '../../components/form';

const ExpereincePackageEdit = () => {
      const { packageId } = useParams();
      const [packages, setPackages] = useState([]);
      const [categories, setCategories] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [selectedPackages, setSelectedPackages] = useState([]);
      const [title, setTitle] = useState('');
      const [items, setItems] = useState('');
      const [category, setCategory] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_packages/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPackages(response.data.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_packages/${packageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTitle(response.data.package.title);
                              setItems(response.data.package.packages);
                              setStatus(response.data.package.status);
                              setCategory(response.data.package.experience_id);
                              setSelectedPackages(response.data.package);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_collections/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const onChange = (newValue) => {
            setItems(newValue);
      };

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  packages: items,
                  status: status,
                  category: category
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_packages/${packageId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col>
                                    <Card>
                                          <CardHeader title="Create Experience Collection">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/experience_packages"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                            value={title}
                                                      />

                                                      <FormSelect
                                                            options={categories}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            selected={category}
                                                            classes={4}
                                                            title="Category"
                                                            setData={
                                                                  setCategory
                                                            }
                                                      />

                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <Col lg={12}>
                                                            <Form.Label>
                                                                  Package{' '}
                                                                  <span className="text-danger">
                                                                        *
                                                                  </span>
                                                            </Form.Label>
                                                            {!loadingData ? (
                                                                  <Select
                                                                        isMulti
                                                                        defaultValue={
                                                                              selectedPackages.package_items &&
                                                                              selectedPackages.package_items.map(
                                                                                    (
                                                                                          index
                                                                                    ) =>
                                                                                          packages[
                                                                                                index
                                                                                          ]
                                                                              )
                                                                        }
                                                                        onChange={
                                                                              onChange
                                                                        }
                                                                        options={
                                                                              packages
                                                                        }
                                                                        classNamePrefix="select"
                                                                  />
                                                            ) : (
                                                                  <div>
                                                                        <Spinner />
                                                                  </div>
                                                            )}
                                                      </Col>
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default ExpereincePackageEdit;
