export const durations = [
      {
            id: '1 nights',
            title: '1 nights'
      },
      {
            id: '2 nights',
            title: '2 nights'
      },
      {
            id: '3 nights',
            title: '3 nights'
      },
      {
            id: '4 nights',
            title: '4 nights'
      },
      {
            id: '5 nights',
            title: '5 nights'
      },
      {
            id: '6 nights',
            title: '6 nights'
      },
      {
            id: '7 nights',
            title: '7 nights'
      },
      {
            id: '8 nights',
            title: '8 nights'
      },
      {
            id: '9 nights',
            title: '9 nights'
      },
      {
            id: '10 nights',
            title: '10 nights'
      },
      {
            id: '11 nights',
            title: '11 nights'
      },
      {
            id: '12 nights',
            title: '12 nights'
      },
      {
            id: '13 nights',
            title: '13 nights'
      },
      {
            id: '14 nights',
            title: '14 nights'
      },
      {
            id: '15 nights',
            title: '15 nights'
      },
      {
            id: '16 nights',
            title: '16 nights'
      },
      {
            id: '17 nights',
            title: '17 nights'
      },
      {
            id: '18 nights',
            title: '18 nights'
      },
      {
            id: '19 nights',
            title: '19 nights'
      },
      {
            id: '20 nights',
            title: '20 nights'
      },
      {
            id: '21 nights',
            title: '21 nights'
      },
      {
            id: '22 nights',
            title: '22 nights'
      },
      {
            id: '23 nights',
            title: '23 nights'
      },
      {
            id: '24 nights',
            title: '24 nights'
      },
      {
            id: '25 nights',
            title: '25 nights'
      }
];
