import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const LinkButton = styled(Link).attrs(() => ({
      className: 'btn btn-link'
}))`
      border-radius: 20px;
      border-width: 2px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 18px;
      border-color: #66615b;
      color: #66615b;
      transition: all 150ms linear;

      &:hover,
      &:focus,
      &:active {
            background-color: #66615b;
            color: black;
            border-color: #66615b;
      }
`;
