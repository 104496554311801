import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const CustomizeEnquiryShow = () => {
      const { enquiryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [item, setItem] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customizes/${enquiryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setItem(response.data.enquiry);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Package Enquiry Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/customize_trip_requests"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="From"
                                                                  value={
                                                                        item.leaving_from
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Destination"
                                                                  value={
                                                                        item.destination
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Full Name"
                                                                  value={
                                                                        item.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email"
                                                                  value={
                                                                        item.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Mobile No. (Whatsapp)"
                                                                  value={
                                                                        item.phone_number
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="No. of Traveller"
                                                                  value={
                                                                        item.no_of_traveller
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Duration"
                                                                  value={
                                                                        item.duration
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Depart"
                                                                  value={
                                                                        item.depart
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Query"
                                                                  value={
                                                                        item.query
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        item.date
                                                                  }
                                                            />
                                                            <ListGroupItem>
                                                                  <strong>
                                                                        Travelers:
                                                                  </strong>{' '}
                                                                  {item
                                                                        .travelers
                                                                        ?.length >
                                                                        0 &&
                                                                        item.travelers?.map(
                                                                              (
                                                                                    item,
                                                                                    index
                                                                              ) => (
                                                                                    <div>
                                                                                          Room{' '}
                                                                                          {
                                                                                                ++index
                                                                                          }{' '}
                                                                                          :{' '}
                                                                                          {
                                                                                                item?.adult
                                                                                          }{' '}
                                                                                          {item.adult &&
                                                                                                'Adult'}
                                                                                          {
                                                                                                ', '
                                                                                          }
                                                                                          {
                                                                                                item?.children
                                                                                          }{' '}
                                                                                          {item.children &&
                                                                                                'Children'}
                                                                                          {item.children &&
                                                                                                item
                                                                                                      .childrenAges
                                                                                                      ?.length >
                                                                                                      0 &&
                                                                                                ' - '}
                                                                                          {item.children &&
                                                                                                item.childrenAges?.map(
                                                                                                      (
                                                                                                            child
                                                                                                      ) => (
                                                                                                            <>
                                                                                                                  {
                                                                                                                        child
                                                                                                                  }
                                                                                                                  {
                                                                                                                        ', '
                                                                                                                  }
                                                                                                            </>
                                                                                                      )
                                                                                                )}
                                                                                    </div>
                                                                              )
                                                                        )}
                                                            </ListGroupItem>
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CustomizeEnquiryShow;
