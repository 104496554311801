import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import ImageSlider from '../../components/sliders';

const ExperienceCollectionShow = () => {
      const { collectionId } = useParams();

      const [loading, setLoading] = useState(false);
      const [collection, setCollection] = useState([]);
      const [images, setImages] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/experience_collections/${collectionId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCollection(response.data.collection);
                              setImages([
                                    {
                                          image: response.data.collection
                                                .second_image
                                    },
                                    {
                                          image: response.data.collection
                                                .third_image
                                    },
                                    {
                                          image: response.data.collection
                                                .fourth_image
                                    },
                                    {
                                          image: response.data.collection
                                                .fifth_image
                                    }
                              ]);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Experience Collection Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/experience_collections"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            {collection.banner_image && (
                                                                  <div className="w-100">
                                                                        <img
                                                                              src={`${collection.banner_image}`}
                                                                              className="w-100 h-100"
                                                                              style={{
                                                                                    objectFit:
                                                                                          'contain'
                                                                              }}
                                                                        />
                                                                  </div>
                                                            )}
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        collection.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        collection.experience
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        collection.date
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="User Name"
                                                                  value={
                                                                        collection.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Title"
                                                                  value={
                                                                        collection.seo_title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Keyword"
                                                                  value={
                                                                        collection.seo_keyword
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Seo Description"
                                                                  value={
                                                                        collection.seo_description
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        collection.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={5}>
                                                      <div>
                                                            <ImageSlider
                                                                  image={
                                                                        collection.image
                                                                  }
                                                                  images={
                                                                        images
                                                                  }
                                                            />
                                                      </div>
                                                      {collection.description && (
                                                            <div className="mt-5 border-top py-2">
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: collection.description
                                                                        }}
                                                                  ></div>
                                                            </div>
                                                      )}
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ExperienceCollectionShow;
